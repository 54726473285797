var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var LocationIcon = function (_a) {
    var color = _a.color;
    return (_jsx("svg", __assign({ xmlns: 'http://www.w3.org/2000/svg', width: '0.83em', height: '1em', viewBox: '0 0 19.415 23.425' }, { children: _jsx("g", __assign({ id: 'noun-location-5289467', transform: 'translate(-117.866)' }, { children: _jsx("path", { id: 'Trazado_160', "data-name": 'Trazado 160', d: 'M127.586,0h-.021a9.7,9.7,0,0,0-9.7,9.7c0,5.988,6.26,12.316,9.308,13.642a1.016,1.016,0,0,0,.809,0c3.047-1.327,9.3-7.655,9.3-13.642a9.7,9.7,0,0,0-9.7-9.7Zm-.01,12.947a3.431,3.431,0,1,1,2.426-1A3.43,3.43,0,0,1,127.575,12.947Z', fill: color ? color : '#90d990' }) })) })));
};
export default LocationIcon;
