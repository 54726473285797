var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var InfoIcon = function (_a) {
    var color = _a.color;
    return (_jsx("svg", __assign({ id: 'Grupo_868', "data-name": 'Grupo 868', xmlns: 'http://www.w3.org/2000/svg', width: '1em', height: '1em', viewBox: '0 0 15.557 15.557' }, { children: _jsx("g", __assign({ id: 'Grupo_457', "data-name": 'Grupo 457' }, { children: _jsx("g", __assign({ id: 'noun-info-2558379' }, { children: _jsx("path", { id: 'Trazado_85', "data-name": 'Trazado 85', d: 'M77.786,0a7.778,7.778,0,1,1-5.5,2.268A7.779,7.779,0,0,1,77.786,0Zm-.594,5.911H75.8a1.154,1.154,0,0,0-.3.042.289.289,0,0,0-.227.241.277.277,0,0,0,.156.32,1.414,1.414,0,0,0,.474.128c.432.02.607.21.541.655-.121.832-.285,1.658-.425,2.489-.095.56-.174,1.122-.266,1.682A1.491,1.491,0,0,0,76.9,13.024a6.64,6.64,0,0,0,1,.064,2.6,2.6,0,0,0,1.89-.818.719.719,0,0,0,.194-.3.369.369,0,0,0-.065-.286.282.282,0,0,0-.244-.026c-.128.042-.241.129-.366.184h0a2.255,2.255,0,0,1-.454.156.443.443,0,0,1-.548-.4,1.5,1.5,0,0,1-.014-.439c.053-.361.128-.719.193-1.077q.266-1.464.54-2.929a2.1,2.1,0,0,0,.078-.812h0a.467.467,0,0,0-.507-.432h-1.4Zm.846-3.46a1.405,1.405,0,1,0,.972.444,1.413,1.413,0,0,0-.972-.444Z', transform: 'translate(-70)', fill: color ? color : '#b5b5c2' }) })) })) })));
};
export default InfoIcon;
