import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Switch, Route } from 'react-router-dom';
import DashboardConsumptionRouter from './consumption/DashboardConsumptionRouter';
import DashboardPrintsRouter from './prints/DashboardPrintsRouter';
import Dashboard from './Dashboard';
var DashboardRouter = function (_a) {
    var user = _a.user;
    var mainPath = '/dashboard';
    return (_jsx(Switch, { children: true &&
            _jsxs(_Fragment, { children: [_jsx(Route, { path: "".concat(mainPath, "/consumption"), children: _jsx(DashboardConsumptionRouter, {}) }), _jsx(Route, { path: "".concat(mainPath, "/prints"), children: _jsx(DashboardPrintsRouter, {}) }), _jsx(Route, { path: mainPath, children: _jsx(Dashboard, {}) })] }) }));
};
export default DashboardRouter;
