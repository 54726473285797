var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var LockIcon = function (_a) {
    var color = _a.color;
    return (_jsx("svg", __assign({ xmlns: 'http://www.w3.org/2000/svg', width: '0.8em', height: '1em', viewBox: '0 0 14.815 18.519' }, { children: _jsx("path", { id: 'noun-lock-5326865', d: 'M175.368,53.144V51.292a4.63,4.63,0,1,0-9.26,0v1.852a2.728,2.728,0,0,0-2.778,2.778V62.4a2.728,2.728,0,0,0,2.778,2.778h9.259a2.728,2.728,0,0,0,2.778-2.778V55.922a2.728,2.728,0,0,0-2.778-2.778Zm-7.408-1.852a2.778,2.778,0,1,1,5.556,0v1.852H167.96Zm3.8,7.871-.093.093v1.3a.926.926,0,0,1-1.852,0v-1.3a1.377,1.377,0,1,1,1.945-.093Z', transform: 'translate(-163.33 -46.662)', fill: color ? color : '#b5b5c2' }) })));
};
export default LockIcon;
