import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Switch, Route } from 'react-router-dom';
import Print from './Print';
import PrintManualRouter from './manual/PrintManualRouter';
import PrintBulkRouter from './bulk/PrintBulkRouter';
import PrintGrRouter from './gr/PrintGrRouter';
import PrintRecordRouter from './record/PrintRecordRouter';
var PrintRouter = function (_a) {
    var user = _a.user;
    var mainPath = '/print';
    return (_jsxs(Switch, { children: [((user === null || user === void 0 ? void 0 : user.role) === 'SA' || (user === null || user === void 0 ? void 0 : user.role) === 'OP' || (user === null || user === void 0 ? void 0 : user.role) === 'MD') &&
                _jsx(Route, { path: "".concat(mainPath, "/manual"), children: _jsx(PrintManualRouter, {}) }), ((user === null || user === void 0 ? void 0 : user.role) === 'SA' || (user === null || user === void 0 ? void 0 : user.role) === 'OP' || (user === null || user === void 0 ? void 0 : user.role) === 'MD') &&
                _jsx(Route, { path: "".concat(mainPath, "/bulk"), children: _jsx(PrintBulkRouter, {}) }), ((user === null || user === void 0 ? void 0 : user.role) === 'SA' || (user === null || user === void 0 ? void 0 : user.role) === 'OP' || (user === null || user === void 0 ? void 0 : user.role) === 'MD') &&
                _jsx(Route, { path: "".concat(mainPath, "/gr"), children: _jsx(PrintGrRouter, {}) }), _jsx(Route, { path: "".concat(mainPath, "/record"), children: _jsx(PrintRecordRouter, {}) }), _jsx(Route, { path: '/print', children: _jsx(Print, {}) })] }));
};
export default PrintRouter;
