"use strict";
var theme = {
    grayScale: {
        gray0: '#000000',
        gray1: '#B5B5C2',
        gray2: '#E9E9ED',
        gray3: '#F8FAFB',
        gray4: '#6F6E6E',
        gray5: "#565656",
        gray6: "#AEAEBA",
        gray7: "#A2A5B4",
        gray8: "#F6F8FA",
        gray9: "#3C3C3C",
    },
    colors: {
        white: "#FFFFFF",
        primary: "#00D800",
        secondary: "#40FF00",
        bg: "#F9FAFB",
        iconHoverOne: "#90D990",
        iconHoverTwo: "#A0FF80",
        error: "#EA3C3C",
        yellow: "#F2D372",
        green: "#82D279",
        red: "#D37083",
        red2: '#D46D6B',
        purple: "#7B5BDE",
        duck: '#FDD174'
    },
    transitions: {
        default: 'all 0.2s ease-in-out'
    },
    fontSizes: {
        small: '12px',
        regular: '16px',
        large: '18px',
    },
    helpers: {
        borderColorInput: '#E9E9ED',
        borderColor: '#DFDFE3',
    },
    fontFamily: "Montserrat",
    borderRadiusOne: '12px',
    borderRadiusTwo: '6px',
};
module.exports = theme;
