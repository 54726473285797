var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Upload, message } from 'antd';
import AppTopBar from '../../app/AppTopBar';
import styled from 'styled-components';
import PrintIcon from '../../icons/PrintIcon';
import { Button } from '../../styles/Button';
import { handleLoadMaster as ApiLoadMaster } from '../../api/print/api';
import { ModalFeedback } from '../../components/ModalFeedback';
import { useTranslation } from 'react-i18next';
var _Dragger = Upload.Dragger;
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  overflow: auto;\n  background-color: #F7F7F8;\n  display: flex;\n  flex-direction: column;\n"], ["\n  width: 100%;\n  height: 100%;\n  overflow: auto;\n  background-color: #F7F7F8;\n  display: flex;\n  flex-direction: column;\n"])));
var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 34px;\n  overflow: auto;\n"], ["\n  padding: 34px;\n  overflow: auto;\n"])));
var Container = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: fit-content;\n\n  .top{\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    gap: 20px;\n    margin-bottom: 40px;\n  }\n  .bottom{\n\n  }\n"], ["\n  width: fit-content;\n\n  .top{\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    gap: 20px;\n    margin-bottom: 40px;\n  }\n  .bottom{\n\n  }\n"])));
var Item = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 360px;\n  height: 100%;\n  .item-title{\n    font-size: 18px;\n    font-weight: 600;\n    color: ", ";\n    margin-bottom: 6px;\n  }\n  .item-container{\n    padding: 24px 24px 20px 36px;\n    background-color: ", ";\n    box-shadow: 0 2px 16px 0 rgba(0,0,0,0.04);\n    border-radius: ", ";\n  }\n  .item-icon{\n    width: 50px;\n    height: 50px;\n    border-radius: 50px;\n    background-color: ", ";\n    font-size: 24px;\n    line-height: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  .item-info{\n    &-subtitle{\n      font-size: 12px;\n      font-weight: 600;\n      color: ", ";\n    }\n    &-text{\n      font-size: 14px;\n      font-weight: 500;\n      color: ", ";\n    }\n  }\n"], ["\n  width: 360px;\n  height: 100%;\n  .item-title{\n    font-size: 18px;\n    font-weight: 600;\n    color: ", ";\n    margin-bottom: 6px;\n  }\n  .item-container{\n    padding: 24px 24px 20px 36px;\n    background-color: ", ";\n    box-shadow: 0 2px 16px 0 rgba(0,0,0,0.04);\n    border-radius: ", ";\n  }\n  .item-icon{\n    width: 50px;\n    height: 50px;\n    border-radius: 50px;\n    background-color: ", ";\n    font-size: 24px;\n    line-height: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  .item-info{\n    &-subtitle{\n      font-size: 12px;\n      font-weight: 600;\n      color: ", ";\n    }\n    &-text{\n      font-size: 14px;\n      font-weight: 500;\n      color: ", ";\n    }\n  }\n"])), function (props) { return props.theme.grayScale.gray5; }, function (props) { return props.theme.colors.white; }, function (props) { return props.theme.borderRadiusOne; }, function (props) { return props.theme.colors.bg; }, function (props) { return props.theme.grayScale.gray5; }, function (props) { return props.theme.grayScale.gray1; });
var FormItem = styled(Form.Item)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 20px;\n  margin-bottom: 0;\n  font-size: 12px;\n  .ant-form-item-label{\n    label{\n      font-size: 12px;\n      font-weight: 600;\n      color: ", ";\n    }\n  }\n"], ["\n  margin-top: 20px;\n  margin-bottom: 0;\n  font-size: 12px;\n  .ant-form-item-label{\n    label{\n      font-size: 12px;\n      font-weight: 600;\n      color: ", ";\n    }\n  }\n"])), function (props) { return props.theme.grayScale.gray5; });
var Dragger = styled(_Dragger)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  \n  .ant-upload{\n    background-color: ", ";\n  }\n  .dragger-placeholder{\n    font-size: 12px;\n  }\n"], ["\n  \n  .ant-upload{\n    background-color: ", ";\n  }\n  .dragger-placeholder{\n    font-size: 12px;\n  }\n"])), function (props) { return props.theme.grayScale.gray8; });
var Footer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  border-top: 1px solid ", ";\n  margin-top: 60px;\n  padding: 14px 24px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 20px;\n\n  .clean-btn{\n    font-size: 12px;\n    font-weight: 600;\n    color: ", ";\n    text-decoration: underline;\n    cursor: pointer;\n  }\n"], ["\n  border-top: 1px solid ", ";\n  margin-top: 60px;\n  padding: 14px 24px;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 20px;\n\n  .clean-btn{\n    font-size: 12px;\n    font-weight: 600;\n    color: ", ";\n    text-decoration: underline;\n    cursor: pointer;\n  }\n"])), function (props) { return props.theme.helpers.borderColorInput; }, function (props) { return props.theme.grayScale.gray5; });
var UpdatesMaterials = function () {
    var _a = useState(undefined), currentModal = _a[0], setCurrentModal = _a[1];
    var _b = useState({ show: false }), error = _b[0], setError = _b[1];
    var _c = useState([]), fileList = _c[0], setFileList = _c[1];
    var _d = useState(false), loading = _d[0], setLoading = _d[1];
    var form = Form.useForm()[0];
    var history = useHistory();
    var t = useTranslation().t;
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var formValues, data, response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, form.validateFields()];
                case 1:
                    formValues = _a.sent();
                    setLoading(true);
                    data = new FormData();
                    data.append('file', formValues.file.file);
                    return [4 /*yield*/, ApiLoadMaster(data)];
                case 2:
                    response = _a.sent();
                    console.log({ response: response });
                    setLoading(false);
                    setCurrentModal(1);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.log(error_1);
                    setLoading(false);
                    setError({ show: true, message: error_1.messages[0] });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Wrapper, { children: [_jsx(AppTopBar, __assign({ sectionName: t('main_menu.updates') }, { children: _jsxs("div", __assign({ style: { marginLeft: 5, fontSize: 16, color: '#000000', fontWeight: 600 } }, { children: ["/ ", t('main_menu.updates_sub_menu.materials_master')] })) })), _jsx(Content, { children: _jsx("div", __assign({ className: 'content' }, { children: _jsx("div", __assign({ className: "content-body" }, { children: _jsx(Form, __assign({ layout: 'vertical', requiredMark: false, form: form }, { children: _jsx(Container, { children: _jsx("div", __assign({ className: "bottom" }, { children: _jsxs(Item, { children: [_jsx("div", __assign({ className: "item-title" }, { children: t('general.labels.file') })), _jsxs("div", __assign({ className: "item-container", style: { padding: '24px 24px 0px 36px' } }, { children: [_jsx("div", __assign({ className: "item-icon" }, { children: _jsx(PrintIcon, { colorOne: '#00D800', colorTwo: '#40FF00' }) })), _jsx("div", __assign({ className: "filter-inputs" }, { children: _jsx(FormItem, __assign({ label: t('general.labels.search_file'), name: 'file' }, { children: _jsx(Dragger, __assign({ accept: ".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xls, application/vnd.ms-excel, .csv, text/csv", multiple: false, fileList: fileList, onRemove: function (file) {
                                                                    setFileList(function (c) {
                                                                        var index = c.indexOf(file);
                                                                        var newFileList = c.slice();
                                                                        newFileList.splice(index, 1);
                                                                        return newFileList;
                                                                    });
                                                                }, beforeUpload: function (file) {
                                                                    var isLt2M = file.size < 20000000;
                                                                    var isCorrect = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                                                                        file.type === 'application/vnd.ms-excel' ||
                                                                        file.type === 'text/csv';
                                                                    if (!isCorrect) {
                                                                        message.error('Solo pudes agregar archivos XLS, XLSX y CSV');
                                                                        return false;
                                                                    }
                                                                    if (!isLt2M) {
                                                                        message.error('El documento no debe pesar más de 20MB');
                                                                        return false;
                                                                    }
                                                                    if (isCorrect && isLt2M) {
                                                                        setFileList([file]);
                                                                        return false;
                                                                    }
                                                                } }, { children: _jsx("p", __assign({ className: 'dragger-placeholder' }, { children: t('general.buttons.browse') })) })) })) })), _jsx(Footer, { children: _jsx(Button, __assign({ style: { width: '112px' }, size: 'large', type: 'primary', disabled: fileList.length === 0, onClick: handleSubmit }, { children: t('general.buttons.load') })) })] }))] }) })) }) })) })) })) }), _jsx(ModalFeedback, { title: _jsxs("div", __assign({ style: { marginTop: '20px' } }, { children: [t('sections.master_section.loading_message'), " ", _jsx("br", {}), " ", _jsx("span", __assign({ style: { fontWeight: '700', color: '#000' } }, { children: t('general.labels.materials_master') }))] })), visible: loading, icon: 'loading' }), _jsx(ModalFeedback, { title: _jsxs("div", __assign({ style: { marginTop: '20px' } }, { children: [t('sections.master_section.success_feedback_message'), " ", _jsx("br", {}), " ", _jsx("span", __assign({ style: { color: '#000', fontWeight: '700' } }, { children: t('general.labels.materials_master') }))] })), icon: 'check', visible: currentModal === 1, onOk: function () {
                    setCurrentModal(undefined);
                    setFileList([]);
                    form.setFieldsValue({ 'file': null });
                } }), _jsx(ModalFeedback, { title: error.message, visible: error.show, type: 'error', icon: '', simple: true, okBtnText: "".concat(t('general.buttons.close')), onOk: function () { return setError({ show: false, message: '' }); } })] }));
};
export default UpdatesMaterials;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
