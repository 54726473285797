var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// import UserTable from './UserTable'
import AppTopBar from '../app/AppTopBar';
import styled from 'styled-components';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  overflow: auto;\n  background-color: #F7F7F8;\n  display: flex;\n  flex-direction: column;\n"], ["\n  width: 100%;\n  height: 100%;\n  overflow: auto;\n  background-color: #F7F7F8;\n  display: flex;\n  flex-direction: column;\n"])));
var ScrollContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 16px;\n  width: 100%;\n  height: 100%;\n  overflow: auto;\n  display: flex;\n  flex-direction: column;\n"], ["\n  padding: 16px;\n  width: 100%;\n  height: 100%;\n  overflow: auto;\n  display: flex;\n  flex-direction: column;\n"])));
var Print = function () {
    return (_jsxs(Wrapper, { children: [_jsx(AppTopBar, { sectionName: "Imprimir" }), _jsx(ScrollContainer, { children: "record" })] }));
};
export default Print;
var templateObject_1, templateObject_2;
