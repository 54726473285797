import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Switch, Route } from 'react-router-dom';
import Printers from './Printers';
import PrintersCreate from './PrintersCreate';
var PrintersRouter = function (_a) {
    var user = _a.user, socket = _a.socket;
    return (_jsx(Switch, { children: ((user === null || user === void 0 ? void 0 : user.role) === 'SA' || (user === null || user === void 0 ? void 0 : user.role) === 'SU' || (user === null || user === void 0 ? void 0 : user.role) === 'SD') &&
            _jsxs(_Fragment, { children: [_jsx(Route, { path: '/printers/create', children: _jsx(PrintersCreate, {}) }), _jsx(Route, { path: '/printers/edit/printerId=:printerId', children: _jsx(PrintersCreate, {}) }), _jsx(Route, { exact: true, path: '/printers', children: _jsx(Printers, { socket: socket }) })] }) }));
};
export default PrintersRouter;
