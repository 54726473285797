var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var CheckIcon = function (_a) {
    var color = _a.color;
    return (_jsx("svg", __assign({ xmlns: 'http://www.w3.org/2000/svg', width: '1em', height: '0.77em', viewBox: '0 0 26.183 20.141' }, { children: _jsx("g", __assign({ id: 'noun-check-1788422', transform: 'translate(-204.205 -167.848)' }, { children: _jsx("path", { id: 'Trazado_88', "data-name": 'Trazado 88', d: 'M227.8,167.849a2.516,2.516,0,0,0-1.729.77c-4.816,4.827-8.494,8.858-12.965,13.42l-4.761-4.023a2.515,2.515,0,1,0-3.237,3.85l6.537,5.532a2.515,2.515,0,0,0,3.394-.141c5.416-5.427,9.272-9.763,14.583-15.086a2.515,2.515,0,0,0-1.823-4.322Z', transform: 'translate(0 0)', fill: color ? color : '#90d990' }) })) })));
};
export default CheckIcon;
