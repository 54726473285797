var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useContext } from 'react';
import { AppCtx } from '../../context/AppContext';
import { Select as _Select, Input as _Input } from 'antd';
import { Table as _Table } from '../../styles/Table';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
var Table = styled(_Table)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .ant-table-container{\n    max-height: 600px;\n  }\n  .ant-table-body{\n    max-height: 400px !important;\n  }\n  .ant-table-fixed-header{\n    padding: 0;\n  }\n"], ["\n  .ant-table-container{\n    max-height: 600px;\n  }\n  .ant-table-body{\n    max-height: 400px !important;\n  }\n  .ant-table-fixed-header{\n    padding: 0;\n  }\n"])));
var Select = styled(_Select)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 60px;\n  font-size: 12px;\n  font-weight: 500;\n  border-radius: ", ";\n\n  &.select-active{\n    border: 1px solid ", ";\n  }\n  .ant-select-arrow{\n    color: ", ";\n  }\n"], ["\n  width: 60px;\n  font-size: 12px;\n  font-weight: 500;\n  border-radius: ", ";\n\n  &.select-active{\n    border: 1px solid ", ";\n  }\n  .ant-select-arrow{\n    color: ", ";\n  }\n"])), function (props) { return props.theme.borderRadiusTwo; }, function (props) { return props.theme.grayScale.gray1; }, function (props) { return props.theme.colors.primary; });
var Input = styled(_Input)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 12px;\n  background-color: ", ";\n  height: 45px;\n\n  &.input-active{\n    border: 1px solid ", ";\n  }\n"], ["\n  font-size: 12px;\n  background-color: ", ";\n  height: 45px;\n\n  &.input-active{\n    border: 1px solid ", ";\n  }\n"])), function (props) { return props.theme.grayScale.gray8; }, function (props) { return props.theme.grayScale.gray1; });
var PrintManulListTable = function (_a) {
    var data = _a.data, onSetTags = _a.onSetTags;
    var ctx = useContext(AppCtx);
    var t = useTranslation().t;
    var _b = useState({
        per_page: 20,
    }), meta = _b[0], setMeta = _b[1];
    var handleOnChange = function (pagination) { };
    var handleInputChange = function (record, e, field) {
        var newItem = data.find(function (d) { return d._id === record._id; });
        var arr = data.map(function (d) {
            var _a;
            return d._id !== record._id ? d : __assign(__assign({}, newItem), (_a = {}, _a[field] = parseInt(e.target.value) || null, _a));
        });
        onSetTags(arr);
        var totalToPrint = __spreadArray([], arr.map(function (t) { return t.quantity; }), true).reduce(function (_t, total) { return _t + total; }, 0);
        ctx === null || ctx === void 0 ? void 0 : ctx.setTotalToPrint(totalToPrint);
    };
    var handleSelectChange = function (record, value, field) {
        var newItem = data.find(function (d) { return d._id === record._id; });
        var arr = data.map(function (d) {
            var _a;
            return d._id !== record._id ? d : __assign(__assign({}, newItem), (_a = {}, _a[field] = value, _a));
        });
        onSetTags(arr);
    };
    var columns = [
        {
            title: 'Stock code',
            dataIndex: 'stockCode',
            key: 'stockCode',
            // width: '14%',
            render: function (value) { return value; }
        },
        {
            title: 'Descripción',
            dataIndex: 'description',
            key: 'description',
            width: '20%'
        },
        {
            title: 'Storage Bin',
            dataIndex: 'storageBin',
            key: 'storageBin',
            // width: '14%',
            render: function (val) { return val; }
        },
        {
            title: 'Purchase Order',
            dataIndex: 'purchaseOrder',
            key: 'purchaseOrder',
            // width: '20%',
            render: function (val, record) { return (_jsx(Input, { maxLength: 10, className: val ? 'input-active' : '', style: { width: '98px' }, placeholder: '0', value: val, onChange: function (e) { return handleInputChange(record, e, 'purchaseOrder'); } })); }
        },
        {
            title: 'Cantidad',
            dataIndex: 'quantity',
            key: 'quantity',
            width: '12%',
            render: function (val, record) { return (_jsx(Input, { className: val ? 'input-active' : '', style: { width: '50px' }, placeholder: '0', value: val, onChange: function (e) { return handleInputChange(record, e, 'quantity'); } })); }
        },
        {
            title: 'Valuation Type',
            dataIndex: 'valuationType',
            key: 'valuationType',
            // width: '14%',
            render: function (val, record) { return (_jsxs(Select, __assign({ className: val ? 'select-active' : '', style: { width: '120px' }, size: 'large', placeholder: 'Seleccionar', defaultValue: val ? val : null, onChange: function (value) { return handleSelectChange(record, value, 'valuationType'); } }, { children: [_jsx(Select.Option, __assign({ value: '1' }, { children: "NEW" })), _jsx(Select.Option, __assign({ value: '2' }, { children: "DAMAGED" })), _jsx(Select.Option, __assign({ value: '3' }, { children: "REPAIRED" })), _jsx(Select.Option, __assign({ value: '4' }, { children: "SCARP" })), _jsx(Select.Option, __assign({ value: '5' }, { children: "--" }))] }))); }
        },
    ];
    return (_jsx(Table, { columns: columns, dataSource: data, onChange: handleOnChange, loading: false, locale: {
            emptyText: 'No hay datos',
        }, scroll: { y: '100%' }, pagination: {
            size: 'small',
            showTotal: function (total, range) { return (_jsxs("div", __assign({ className: 'pagination-content' }, { children: [_jsxs(Select, __assign({ defaultValue: 20, size: 'small', onChange: function (value) { return setMeta({
                            per_page: value,
                        }); } }, { children: [_jsx(Select.Option, __assign({ value: 10 }, { children: "10" })), _jsx(Select.Option, __assign({ value: 20 }, { children: "20" })), _jsx(Select.Option, __assign({ value: 50 }, { children: "50" }))] })), _jsx("span", { children: "".concat(range[0], "-").concat(range[1], " ").concat(t('general.labels.of'), " ").concat(data.length, " ").concat(t('general.labels.results')) })] }))); },
            pageSize: meta === null || meta === void 0 ? void 0 : meta.per_page,
            total: data.length,
            showSizeChanger: false
        }, rowKey: "_id", showSorterTooltip: false }));
};
export default PrintManulListTable;
var templateObject_1, templateObject_2, templateObject_3;
