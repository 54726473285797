var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Bar as _Bar } from '@ant-design/plots';
import styled from 'styled-components';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 30px 20px;\n"], ["\n  padding: 30px 20px;\n"])));
var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  padding: 20px;\n  border: 1px solid ", ";\n  border-radius: ", ";\n"], ["\n  background-color: ", ";\n  padding: 20px;\n  border: 1px solid ", ";\n  border-radius: ", ";\n"])), function (props) { return props.theme.colors.white; }, function (props) { return props.theme.helpers.borderColorInput; }, function (props) { return props.theme.borderRadiusOne; });
var Bar = styled(_Bar)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  .g2-tooltip-title{\n    color: ", ";\n    font-weight: 600;\n  }\n  .g2-tooltip-name{\n    color: ", ";\n    font-weight: 600;\n  }\n  .g2-tooltip-value{\n    color: ", ";\n    font-weight: 600;\n  }\n"], ["\n  .g2-tooltip-title{\n    color: ", ";\n    font-weight: 600;\n  }\n  .g2-tooltip-name{\n    color: ", ";\n    font-weight: 600;\n  }\n  .g2-tooltip-value{\n    color: ", ";\n    font-weight: 600;\n  }\n"])), function (props) { return props.theme.grayScale.gray5; }, function (props) { return props.theme.grayScale.gray1; }, function (props) { return props.theme.grayScale.gray4; });
var DashboardCharts = function (_a) {
    var historyPrints = _a.historyPrints;
    var _b = useState([]), data = _b[0], setData = _b[1];
    useEffect(function () {
        if (historyPrints) {
            var arr = historyPrints.data.dashboardPrints.map(function (r) { return ({
                label: r._id.storageBinId,
                type: r._id.author.fullName,
                value: r.total,
                title: 'Manual'
            }); });
            setData(arr.concat([
                {
                    label: '',
                    type: '',
                    value: 0,
                    title: '',
                }
            ]));
        }
    }, [historyPrints]);
    var config = {
        data: data,
        isGroup: true,
        xField: 'value',
        yField: 'label',
        seriesField: 'type',
        dodgePadding: 4,
        legend: false,
        label: false,
        maxBarWidth: 8,
        color: ['#7B5BDE', '#D37083', '#F2D372', '#90D990'],
        tooltip: {
            shared: false,
            showTitle: true,
            title: function (title, _) {
                return _.title;
            }
        },
    };
    return (_jsx(Wrapper, { children: _jsx(Content, { children: _jsx(Bar, __assign({}, config)) }) }));
};
export default DashboardCharts;
var templateObject_1, templateObject_2, templateObject_3;
