var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { gql, useMutation } from "@apollo/client";
var SET_PRINTER = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation CreatePrinter($createPrinterDto: CreatePrinterDto!){\n    create(createPrinterDto:$createPrinterDto){\n      _id\n      code\n      controlStock\n      currentStock\n      lastPurchaseDate\n      lastPurchaseQuantity\n      name\n      status\n      storageBinId\n    }\n  }\n"], ["\n  mutation CreatePrinter($createPrinterDto: CreatePrinterDto!){\n    create(createPrinterDto:$createPrinterDto){\n      _id\n      code\n      controlStock\n      currentStock\n      lastPurchaseDate\n      lastPurchaseQuantity\n      name\n      status\n      storageBinId\n    }\n  }\n"])));
export var useSetPrinter = function () {
    var _a = useMutation(SET_PRINTER, { context: { clientName: 'printPath' }, update: function (cache, _a) {
            var create = _a.data.create;
            cache.modify({
                fields: {
                    printersFindAll: function (existingPrinters) {
                        if (existingPrinters === void 0) { existingPrinters = []; }
                        var newPrinterRef = cache.writeFragment({
                            data: create,
                            fragment: gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n                fragment NewPrinter on Printer {\n                  _id\n                  type\n                }\n              "], ["\n                fragment NewPrinter on Printer {\n                  _id\n                  type\n                }\n              "])))
                        });
                        return __spreadArray(__spreadArray([], existingPrinters, true), [newPrinterRef], false);
                    }
                }
            });
        }
    }), setPrinter = _a[0], _b = _a[1], loading = _b.loading, error = _b.error, data = _b.data, called = _b.called;
    return [setPrinter, loading, error, data, called];
};
var templateObject_1, templateObject_2;
