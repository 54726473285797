var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql, useQuery } from "@apollo/client";
export var GET_HISTORY_COUNT = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query HistoryCount($historyQueryDto: HistoryQueryDto!){\n    historyCount(historyQueryDto:$historyQueryDto)\n  }\n"], ["\n  query HistoryCount($historyQueryDto: HistoryQueryDto!){\n    historyCount(historyQueryDto:$historyQueryDto)\n  }\n"])));
export var useGetHistoryCount = function () {
    var _a = useQuery(GET_HISTORY_COUNT, { variables: { historyQueryDto: { limit: 9999, offset: 0 } }, context: { clientName: 'printPath' } }), loading = _a.loading, error = _a.error, data = _a.data;
    return { loading: loading, error: error, data: data };
};
var templateObject_1;
