var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { Button as _Button } from 'antd';
import { transparentize } from 'polished';
export var Button = styled(_Button)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n\n  font-weight: 600;\n  font-size: 12px;\n  height: 45px;\n\n  &:disabled {\n    background-color: ", ";\n    border-color: ", ";\n    color: ", ";\n    &:hover{\n      background-color: ", ";\n      border-color: ", ";\n    }\n  }\n  /* &:disabled {\n    background-color: ", ";\n    border-color: ", ";\n    color: ", ";\n    &:hover{\n      background-color: ", ";\n      border-color: ", ";\n      color: ", ";\n    }\n  } */\n\n  \n  ", "\n\n    ", "\n\n  ", "\n\n    ", "\n\n    ", "\n\n  ", "\n"], ["\n\n  font-weight: 600;\n  font-size: 12px;\n  height: 45px;\n\n  &:disabled {\n    background-color: ", ";\n    border-color: ", ";\n    color: ", ";\n    &:hover{\n      background-color: ", ";\n      border-color: ", ";\n    }\n  }\n  /* &:disabled {\n    background-color: ", ";\n    border-color: ", ";\n    color: ", ";\n    &:hover{\n      background-color: ", ";\n      border-color: ", ";\n      color: ", ";\n    }\n  } */\n\n  \n  ", "\n\n    ", "\n\n  ", "\n\n    ", "\n\n    ", "\n\n  ", "\n"])), function (props) { return props.theme.helpers.borderColor; }, function (props) { return props.theme.helpers.borderColor; }, function (props) { return props.theme.grayScale.gray6; }, function (props) { return transparentize(0.5, props.theme.helpers.borderColor); }, function (props) { return transparentize(0.95, props.theme.helpers.borderColor); }, function (props) { return transparentize(0.5, props.theme.colors.primary); }, function (props) { return transparentize(0.95, props.theme.colors.primary); }, function (props) { return props.theme.colors.white; }, function (props) { return transparentize(0.5, props.theme.colors.primary); }, function (props) { return transparentize(0.95, props.theme.colors.primary); }, function (props) { return props.theme.colors.white; }, function (_a) {
    var type = _a.type;
    return type === 'duck' && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      background-color: ", ";\n      color: ", ";\n      border-color: ", ";\n      &:hover,\n      &:active,\n      &:focus {\n        border-color: ", ";\n        color: ", ";\n        background-color: ", " !important;\n      }\n    "], ["\n      background-color: ", ";\n      color: ", ";\n      border-color: ", ";\n      &:hover,\n      &:active,\n      &:focus {\n        border-color: ", ";\n        color: ", ";\n        background-color: ", " !important;\n      }\n    "])), function (props) { return props.theme.colors.duck; }, function (props) { return props.theme.grayScale.gray0; }, function (props) { return props.theme.colors.duck; }, function (props) { return props.theme.colors.duck; }, function (props) { return props.theme.grayScale.gray0; }, function (props) {
        return props.theme.colors.duck;
    });
}, function (_a) {
    var type = _a.type;
    return type === 'alienGreen' && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        background-color: ", ";\n        color: ", ";\n        border-color: ", ";\n        &:hover,\n        &:active,\n        &:focus {\n          border-color: ", ";\n          color: ", ";\n          background-color: ", " !important;\n        }\n      "], ["\n        background-color: ", ";\n        color: ", ";\n        border-color: ", ";\n        &:hover,\n        &:active,\n        &:focus {\n          border-color: ", ";\n          color: ", ";\n          background-color: ", " !important;\n        }\n      "])), function (props) { return props.theme.colors.primary; }, function (props) { return props.theme.grayScale.gray0; }, function (props) { return props.theme.colors.primary; }, function (props) { return props.theme.colors.primary; }, function (props) { return props.theme.grayScale.gray0; }, function (props) {
        return props.theme.colors.primary;
    });
}, function (_a) {
    var type = _a.type;
    return type === 'secondary' && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      background-color: ", ";\n      color: ", ";\n      border-color: ", ";\n      &:hover,\n      &:active,\n      &:focus {\n        border-color: ", ";\n        color: ", ";\n        background-color: ", " !important;\n      }\n    "], ["\n      background-color: ", ";\n      color: ", ";\n      border-color: ", ";\n      &:hover,\n      &:active,\n      &:focus {\n        border-color: ", ";\n        color: ", ";\n        background-color: ", " !important;\n      }\n    "])), function (props) { return props.theme.colors.secondary; }, function (props) { return props.theme.colors.white; }, function (props) { return props.theme.colors.secondary; }, function (props) { return props.theme.colors.secondary; }, function (props) { return props.theme.colors.white; }, function (props) {
        return props.theme.colors.secondary;
    });
}, function (_a) {
    var type = _a.type;
    return type === 'white' && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      background-color: ", ";\n      color: ", ";\n      &:hover,\n      &:active,\n      &:focus {\n        background-color: ", " !important;\n      }\n    "], ["\n      background-color: ", ";\n      color: ", ";\n      &:hover,\n      &:active,\n      &:focus {\n        background-color: ", " !important;\n      }\n    "])), function (props) { return props.theme.colors.white; }, function (props) { return props.theme.colors.primary; }, function (props) { return props.theme.colors.white; });
}, function (_a) {
    var type = _a.type;
    return type === 'gray' && css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      background-color: ", ";\n      color: ", ";\n      font-weight: 700;\n      border-color: ", ";\n      &:hover,\n      &:active,\n      &:focus {\n        background-color: ", " !important;\n        border-color: ", ";\n        color: ", ";\n      }\n    "], ["\n      background-color: ", ";\n      color: ", ";\n      font-weight: 700;\n      border-color: ", ";\n      &:hover,\n      &:active,\n      &:focus {\n        background-color: ", " !important;\n        border-color: ", ";\n        color: ", ";\n      }\n    "])), function (props) { return props.theme.grayScale.gray8; }, function (props) { return props.theme.grayScale.gray5; }, function (props) { return props.theme.grayScale.gray8; }, function (props) { return props.theme.grayScale.gray5; }, function (props) { return props.theme.grayScale.gray5; }, function (props) { return props.theme.colors.white; });
}, function (props) {
    return props.icon && css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      display: flex;\n      align-items: center;\n      justify-content: flex-start;\n      svg  + span{\n        margin-left: 8px;\n      }\n      /* > * {\n        line-height: 0 !important;\n        font-size: 20px;\n      } */\n    "], ["\n      display: flex;\n      align-items: center;\n      justify-content: flex-start;\n      svg  + span{\n        margin-left: 8px;\n      }\n      /* > * {\n        line-height: 0 !important;\n        font-size: 20px;\n      } */\n    "])));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
