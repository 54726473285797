var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql, useMutation } from "@apollo/client";
var PRINTS_CREATE = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation PrintsCreate($createPrintDtos:[CreatePrintDto!]!){\n  printsCreate(createPrintDtos: $createPrintDtos){\n    description\n    origin\n    printerId\n    purchaseOrder\n    quantity\n    stockCode\n    storageBin\n    valuationType\n  }\n}\n"], ["\n  mutation PrintsCreate($createPrintDtos:[CreatePrintDto!]!){\n  printsCreate(createPrintDtos: $createPrintDtos){\n    description\n    origin\n    printerId\n    purchaseOrder\n    quantity\n    stockCode\n    storageBin\n    valuationType\n  }\n}\n"])));
export var usePrintsCreate = function () {
    var _a = useMutation(PRINTS_CREATE, { context: { clientName: 'printPath' }
    }), setPrintsCreate = _a[0], _b = _a[1], loading = _b.loading, error = _b.error, data = _b.data, called = _b.called;
    return [setPrintsCreate, loading, error, data, called];
};
var templateObject_1;
