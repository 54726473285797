var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var PrinterEnabledIcon = function () {
    return (_jsx("svg", __assign({ xmlns: 'http://www.w3.org/2000/svg', width: '1em', height: '0.99em', viewBox: '0 0 23.757 23.425' }, { children: _jsxs("g", __assign({ id: 'noun-print-976382', transform: 'translate(0 0)' }, { children: [_jsx("path", { id: 'Trazado_74', "data-name": 'Trazado 74', d: 'M194.559,181.426v9.651a2.227,2.227,0,0,1-2.227,2.227h-1.485v2.227a2.227,2.227,0,0,1-2.227,2.227H176.742a2.227,2.227,0,0,1-2.227-2.227V193.3H173.03a2.227,2.227,0,0,1-2.227-2.227v-9.651a2.227,2.227,0,0,1,2.227-2.227h1.485v5.2h16.333v-5.2h1.485a2.227,2.227,0,0,1,2.227,2.227Zm-6.682,8.166H177.484v5.2h10.394Z', transform: 'translate(-170.803 -174.334)', fill: '#82d279' }), _jsx("g", __assign({ id: 'noun-check-1788422', transform: 'translate(-197.635 -167.848)' }, { children: _jsx("path", { id: 'Trazado_88', "data-name": 'Trazado 88', d: 'M213.771,167.849a1.02,1.02,0,0,0-.7.312c-1.953,1.957-3.444,3.592-5.257,5.441l-1.93-1.631a1.02,1.02,0,1,0-1.313,1.561l2.651,2.243a1.02,1.02,0,0,0,1.376-.057c2.2-2.2,3.759-3.958,5.913-6.117a1.02,1.02,0,0,0-.739-1.752Z', transform: 'translate(0 0)', fill: '#82d279' }) }))] })) })));
};
export default PrinterEnabledIcon;
