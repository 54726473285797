var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql, useQuery } from "@apollo/client";
export var GET_USERS_COUNT = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query UsersCount($limit:Float!, $offset:Float!, $role:Role, $status:Status, $userMail:String){\n    UsersCount(limit:$limit, offset:$offset, role:$role, status:$status, userMail:$userMail)\n  }\n"], ["\n  query UsersCount($limit:Float!, $offset:Float!, $role:Role, $status:Status, $userMail:String){\n    UsersCount(limit:$limit, offset:$offset, role:$role, status:$status, userMail:$userMail)\n  }\n"])));
export var useGetUsersCount = function () {
    var _a = useQuery(GET_USERS_COUNT, { variables: { limit: 9999, offset: 0 }, context: { clientName: 'authPath' } }), loading = _a.loading, error = _a.error, data = _a.data;
    return { loading: loading, error: error, data: data };
};
var templateObject_1;
