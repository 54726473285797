var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql, useLazyQuery } from "@apollo/client";
export var GET_HISTORY_PRINTS = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query DashboardPrints($dashboardPrintsQueryDto: DashboardPrintsQueryDto!){\n    dashboardPrints(dashboardPrintsQueryDto:$dashboardPrintsQueryDto) {\n      _id {\n        storageBinId,\n        author {\n          _id,\n          userMail,\n          fullName\n        }\n      }\n      total\n    }\n  }\n"], ["\n  query DashboardPrints($dashboardPrintsQueryDto: DashboardPrintsQueryDto!){\n    dashboardPrints(dashboardPrintsQueryDto:$dashboardPrintsQueryDto) {\n      _id {\n        storageBinId,\n        author {\n          _id,\n          userMail,\n          fullName\n        }\n      }\n      total\n    }\n  }\n"])));
export var useGetHistoryPrints = function () {
    var _a = useLazyQuery(GET_HISTORY_PRINTS, { context: { clientName: 'printsPath' } }), getHistoryPrints = _a[0], _b = _a[1], loading = _b.loading, error = _b.error, data = _b.data;
    return [getHistoryPrints, loading, error, data];
};
var templateObject_1;
