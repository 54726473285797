var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var PrinterDisabledIcon = function () {
    return (_jsx("svg", __assign({ xmlns: 'http://www.w3.org/2000/svg', width: '1em', height: '0.98em', viewBox: '0 0 23.756 23.299' }, { children: _jsx("path", { id: 'Uni\u00F3n_4', "data-name": 'Uni\u00F3n 4', d: 'M-2024.846-17679.488a2.217,2.217,0,0,1-1.573-.648,2.234,2.234,0,0,1-.653-1.578v-2.225h-1.488a2.23,2.23,0,0,1-1.573-.654,2.231,2.231,0,0,1-.653-1.572v-9.654a2.224,2.224,0,0,1,.653-1.572,2.23,2.23,0,0,1,1.573-.654h1.488v5.2h16.332v-5.2h1.484a2.233,2.233,0,0,1,1.577.654,2.22,2.22,0,0,1,.649,1.572v9.654a2.227,2.227,0,0,1-.649,1.572,2.233,2.233,0,0,1-1.577.654h-1.484v2.225a2.234,2.234,0,0,1-.653,1.578,2.217,2.217,0,0,1-1.573.648Zm.742-2.969h10.4v-5.2h-10.4Zm7.317-12.963-2.119-2.125-2.124,2.125a1,1,0,0,1-1.413,0,1,1,0,0,1,0-1.418l2.124-2.119-2.124-2.119a1,1,0,0,1,0-1.418,1,1,0,0,1,1.413,0l2.124,2.125,2.119-2.125a1,1,0,0,1,1.417,0,1,1,0,0,1,0,1.418l-2.124,2.119,2.124,2.119a1,1,0,0,1,0,1.418,1,1,0,0,1-.711.293A1,1,0,0,1-2016.786-17695.42Z', transform: 'translate(2030.786 17702.787)', fill: '#d46d6b' }) })));
};
export default PrinterDisabledIcon;
