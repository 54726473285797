var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useReducer } from 'react';
import appReducer from './appReducer';
export var AppCtx = createContext(null);
export var SET_AUTH = 'SET_AUTH';
export var SET_PRINTS = 'SET_PRINTS';
export var SET_SELECTED_PRINTER = 'SET_SELECTED_PRINTER';
export var SET_TAGS_TO_PRINT = 'SET_TAGS_TO_PRINT';
export var SET_PRINT_TYPE = 'SET_PRINT_TYPE';
export var SET_TOTAL_TO_PRINT = 'SET_TOTAL_TO_PRINT';
export var SET_LNG = 'SET_LNG';
var AppProvider = function (_a) {
    var children = _a.children;
    var initialState = {
        isAuthenticated: false,
        prints: [],
        selectedPrinter: undefined,
        tagsToPrint: undefined,
        printType: undefined,
        totalToPrint: 0,
        lng: 'es'
    };
    var _b = useReducer(appReducer, initialState), state = _b[0], dispatch = _b[1];
    var isAuthenticated = state.isAuthenticated, prints = state.prints, selectedPrinter = state.selectedPrinter, tagsToPrint = state.tagsToPrint, printType = state.printType, totalToPrint = state.totalToPrint, lng = state.lng;
    var handleAuthed = function (value) {
        dispatch({
            type: SET_AUTH,
            payload: value
        });
    };
    var setPrints = function (value) {
        dispatch({
            type: SET_PRINTS,
            payload: value
        });
    };
    var setPrinter = function (value) {
        dispatch({
            type: SET_SELECTED_PRINTER,
            payload: value
        });
    };
    var setTagsToPrint = function (value) {
        dispatch({
            type: SET_TAGS_TO_PRINT,
            payload: value
        });
    };
    var setPrintType = function (value) {
        dispatch({
            type: SET_PRINT_TYPE,
            payload: value
        });
    };
    var setTotalToPrint = function (value) {
        dispatch({
            type: SET_TOTAL_TO_PRINT,
            payload: value
        });
    };
    var setLng = function (value) {
        dispatch({
            type: SET_LNG,
            payload: value
        });
    };
    return (_jsx(AppCtx.Provider, __assign({ value: {
            isAuthenticated: isAuthenticated,
            prints: prints,
            selectedPrinter: selectedPrinter,
            tagsToPrint: tagsToPrint,
            printType: printType,
            totalToPrint: totalToPrint,
            lng: lng,
            setPrinter: setPrinter,
            handleAuthed: handleAuthed,
            setPrints: setPrints,
            setTagsToPrint: setTagsToPrint,
            setPrintType: setPrintType,
            setTotalToPrint: setTotalToPrint,
            setLng: setLng
        } }, { children: children })));
};
export default AppProvider;
