import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Switch, Route } from 'react-router-dom';
import UpdatesMaterialsRouter from './materials/UpdatesMaterialsRouter';
import Updates from './Updates';
import UpdatesYardsRouter from './yards/UpdatesYardsRouter';
var UpdatesRouter = function (_a) {
    var user = _a.user;
    var mainPath = '/updates';
    return (_jsx(Switch, { children: ((user === null || user === void 0 ? void 0 : user.role) === 'SA' || (user === null || user === void 0 ? void 0 : user.role) === 'SU') &&
            _jsxs(_Fragment, { children: [_jsx(Route, { path: "".concat(mainPath, "/yards-master"), children: _jsx(UpdatesYardsRouter, {}) }), _jsx(Route, { path: "".concat(mainPath, "/materials-master"), children: _jsx(UpdatesMaterialsRouter, {}) }), _jsx(Route, { path: mainPath, children: _jsx(Updates, {}) })] }) }));
};
export default UpdatesRouter;
