var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var PrintIcon = function (_a) {
    var colorOne = _a.colorOne, colorTwo = _a.colorTwo;
    return (_jsxs("svg", __assign({ id: 'noun-print-976382', xmlns: 'http://www.w3.org/2000/svg', width: '1em', height: '1em', viewBox: '0 0 23.757 23.425' }, { children: [_jsx("path", { id: 'Trazado_74', "data-name": 'Trazado 74', d: 'M194.559,181.426v9.651a2.227,2.227,0,0,1-2.227,2.227h-1.485v2.227a2.227,2.227,0,0,1-2.227,2.227H176.742a2.227,2.227,0,0,1-2.227-2.227V193.3H173.03a2.227,2.227,0,0,1-2.227-2.227v-9.651a2.227,2.227,0,0,1,2.227-2.227h1.485v5.2h16.333v-5.2h1.485a2.227,2.227,0,0,1,2.227,2.227Zm-6.682,8.166H177.484v5.2h10.394Z', transform: 'translate(-170.803 -174.334)', fill: colorOne ? colorOne : '#b5b5c2' }), _jsx("path", { id: 'Trazado_75', "data-name": 'Trazado 75', d: 'M272.278,108.967H260.4v-5.939a2.227,2.227,0,0,1,2.227-2.227h7.424a2.227,2.227,0,0,1,2.227,2.227Z', transform: 'translate(-254.461 -100.8)', fill: colorTwo ? colorTwo : '#e9e9ed' })] })));
};
export default PrintIcon;
