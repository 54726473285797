var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useContext } from 'react';
import { Route, Switch, Router } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import { createBrowserHistory } from 'history';
import Cookies from 'universal-cookie';
import App from '../app/App';
import AuthLogin from '../auth/AuthLogin';
import { AppCtx } from '../context/AppContext';
import { useGetProfile } from '../graphql/useGetProfile';
import axios from 'axios';
import { useGetPrinters } from '../graphql/print/useGetPrinters';
import AuthRequestReset from '../auth/AuthRequestReset';
import AuthResetPassword from '../auth/AuthResetPassword';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import esTranslation from '../i18n/es.json';
import enTranslation from '../i18n/en.json';
export var history = createBrowserHistory();
var cookies = new Cookies;
export default function MainRouter() {
    var _this = this;
    var _a = useState(true), loading = _a[0], setLoading = _a[1];
    var ctx = useContext(AppCtx);
    var authed = ctx === null || ctx === void 0 ? void 0 : ctx.isAuthenticated;
    var cookie = cookies.get('smartbot-web');
    i18n
        .use(initReactI18next)
        .init({
        resources: {
            es: {
                translation: esTranslation
            },
            en: {
                translation: enTranslation
            }
        },
        lng: ctx === null || ctx === void 0 ? void 0 : ctx.lng,
        fallbackLng: 'es',
        interpolation: {
            escapeValue: false,
        },
        debug: false
    });
    var getProfile = useGetProfile()[0];
    var getPrinters = useGetPrinters()[0];
    useEffect(function () {
        handleInitialData();
    }, []);
    var handleInitialData = function () { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(cookie !== undefined)) return [3 /*break*/, 3];
                    axios.defaults.headers.common['Authorization'] = "Bearer ".concat(cookie);
                    return [4 /*yield*/, getPrinters()];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, getProfile()];
                case 2:
                    res = _a.sent();
                    if (!res.error) {
                        ctx === null || ctx === void 0 ? void 0 : ctx.handleAuthed(true);
                    }
                    else {
                        cookies.remove('smartbot-web');
                    }
                    setLoading(false);
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return loading === true ?
        _jsx("div", __assign({ style: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column' } }, { children: "Cargando..." }))
        : (_jsx(I18nextProvider, __assign({ i18n: i18n }, { children: _jsx(Router, __assign({ history: history }, { children: _jsxs(Switch, { children: [_jsx(PublicRoute, { authed: authed, path: '/auth', component: AuthLogin }), _jsx(PublicRoute, { authed: authed, path: '/restore', component: AuthRequestReset }), _jsx(PublicRoute, { authed: authed, path: '/recover/token=:token', component: AuthResetPassword }), _jsx(PrivateRoute, { authed: authed, path: "/", component: App }), _jsx(Route, { render: function () { return _jsx("h1", { children: "Oops! Esta ruta no existe" }); } })] }) })) })));
}
