var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Select as _Select, DatePicker as _DatePicker, Input as _Input } from 'antd';
import { useHistory } from 'react-router-dom';
import useDebounce from '../../customHooks/useDebounce';
import DashboardIcon from '../../icons/DashboardIcon';
import { useGetUsers } from '../../graphql/users/useGetUsers';
import { useGetWarehouses } from '../../graphql/useGetWarehouses';
import { useTranslation } from 'react-i18next';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 16px 32px;\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-top: 1px solid ", ";\n  border-bottom: 1px solid ", ";\n"], ["\n  padding: 16px 32px;\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-top: 1px solid ", ";\n  border-bottom: 1px solid ", ";\n"])), function (props) { return props.theme.colors.white; }, function (props) { return props.theme.grayScale.gray2; }, function (props) { return props.theme.grayScale.gray2; });
var FilterGroup = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n"], ["\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n"])));
var FilterGroupTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-right: 16px;\n\n"], ["\n  margin-right: 16px;\n\n"])));
var Filters = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  gap: 16px;\n  display: flex;\n"], ["\n  gap: 16px;\n  display: flex;\n"])));
var Filter = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  /* width: 160px;\n  > div {\n    width: 160px;\n  } */\n"], ["\n  /* width: 160px;\n  > div {\n    width: 160px;\n  } */\n"])));
var FilterAction = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 38px;\n  height: 38px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 23px;\n  line-height: 0;\n  background-color: ", ";\n  border-radius: ", ";\n  cursor: pointer;\n"], ["\n  width: 38px;\n  height: 38px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 23px;\n  line-height: 0;\n  background-color: ", ";\n  border-radius: ", ";\n  cursor: pointer;\n"])), function (props) { return props.theme.grayScale.gray8; }, function (props) { return props.theme.borderRadiusTwo; });
var Input = styled(_Input)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n\n  background-color: ", " !important;\n  font-size: 12px;\n  height: 43px;\n  .ant-input{\n    font-weight: 600;\n    background-color: ", ";\n  }\n"], ["\n\n  background-color: ", " !important;\n  font-size: 12px;\n  height: 43px;\n  .ant-input{\n    font-weight: 600;\n    background-color: ", ";\n  }\n"])), function (props) { return props.theme.grayScale.gray8; }, function (props) { return props.theme.grayScale.gray8; });
var Select = styled(_Select)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 100%;\n  font-weight: 600;\n  font-size: 12px;\n  .ant-select-selector{\n    height: 43px !important;\n  }\n"], ["\n  width: 100%;\n  font-weight: 600;\n  font-size: 12px;\n  .ant-select-selector{\n    height: 43px !important;\n  }\n"])));
var RangePicker = styled(_DatePicker.RangePicker)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  \n  background-color: ", " !important;\n  height: 43px;\n  .ant-picker-input{\n    input{\n      font-size: 12px;\n    }\n  }\n  .ant-picker-suffix{\n    color: ", ";\n  }\n"], ["\n  \n  background-color: ", " !important;\n  height: 43px;\n  .ant-picker-input{\n    input{\n      font-size: 12px;\n    }\n  }\n  .ant-picker-suffix{\n    color: ", ";\n  }\n"])), function (props) { return props.theme.grayScale.gray8; }, function (props) { return props.theme.colors.primary; });
var DashboardPrintsTableTools = function (_a) {
    var showCharts = _a.showCharts, onFilters = _a.onFilters;
    var history = useHistory();
    var _b = useState(false), color = _b[0], setColor = _b[1];
    var _c = useState(), searchBy = _c[0], setSearchBy = _c[1];
    var debouncedSearchBy = useDebounce(searchBy, 500);
    var t = useTranslation().t;
    var _e = useGetUsers(), getUsers = _e[0], usersLoading = _e[1], usersError = _e[2], usersData = _e[3];
    var _f = useGetWarehouses(), getWarehouses = _f[0], whLoading = _f[1], whError = _f[2], whData = _f[3];
    useEffect(function () {
        handleSelects();
    }, []);
    React.useEffect(function () {
        handleFilters(debouncedSearchBy === null || debouncedSearchBy === void 0 ? void 0 : debouncedSearchBy.value, debouncedSearchBy === null || debouncedSearchBy === void 0 ? void 0 : debouncedSearchBy.key);
    }, [debouncedSearchBy]);
    var handleFilters = function (value, key) {
        console.log({ value: value });
        var endDate = new Date();
        var startDate = new Date(new Date().setDate(endDate.getDate() - 30));
        if (key === 'dates') {
            return onFilters(function (f) { return (__assign(__assign({}, f), { startDate: value ? value[0]._d : startDate, endDate: value ? value[1]._d : endDate })); });
        }
        onFilters(function (f) {
            var _a;
            return (__assign(__assign({}, f), (_a = {}, _a[key] = value, _a)));
        });
    };
    var handleSelects = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Promise.all([getUsers({ variables: { limit: 9999, offset: 0 } }), getWarehouses()])];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(Wrapper, { children: _jsxs(FilterGroup, { children: [_jsxs(Filters, { children: [_jsx(Filter, { children: _jsx(RangePicker, { size: 'large', style: { width: 240 }, onChange: function (e) { return setSearchBy({ value: e, key: 'dates' }); } }) }), _jsx(Filter, __assign({ style: { width: '160px' } }, { children: _jsx(Select, __assign({ size: "large", placeholder: "".concat(t('general.placeholders.user')), allowClear: true, onChange: function (value) { return setSearchBy({ value: value, key: 'user' }); } }, { children: usersData === null || usersData === void 0 ? void 0 : usersData.findAll.map(function (u) { return (_jsx(Select.Option, __assign({ value: u._id }, { children: u.fullName }), u._id)); }) })) })), _jsx(Filter, __assign({ style: { width: '120px' } }, { children: _jsx(Select, __assign({ size: "large", placeholder: "".concat(t('general.placeholders.warehouse')), allowClear: true, onChange: function (value) { return setSearchBy({ value: value, key: 'warehouse' }); } }, { children: whData === null || whData === void 0 ? void 0 : whData.warehouses.map(function (wh, index) { return (_jsx(Select.Option, __assign({ value: wh.storageBinId }, { children: wh.storageBinId }), index)); }) })) }))] }), _jsx(FilterAction, __assign({ onClick: function () {
                        showCharts(function (state) { return !state; });
                        setColor(!color);
                    } }, { children: _jsx(DashboardIcon, { color: color ? '#00D800' : '' }) }))] }) }));
};
export default DashboardPrintsTableTools;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
