var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var TagIcon = function (_a) {
    var colorOne = _a.colorOne, colorTwo = _a.colorTwo;
    return (_jsxs("svg", __assign({ id: 'noun-label-1009467', xmlns: 'http://www.w3.org/2000/svg', width: '0.63em', height: '1em', viewBox: '0 0 14.494 23.163' }, { children: [_jsx("path", { id: 'Trazado_108', "data-name": 'Trazado 108', d: 'M187.009,79.993H176.665a2.081,2.081,0,0,1-2.075-2.075V64.291a5.256,5.256,0,0,1,2.9-5.051h2.777V61.8a3.514,3.514,0,1,0,3.13,0V59.24h2.736a4.851,4.851,0,0,1,2.947,4.667V77.918a2.081,2.081,0,0,1-2.075,2.075Z', transform: 'translate(-174.59 -56.83)', fill: colorOne ? colorOne : '#b5b5c2', fillRule: 'evenodd' }), _jsx("path", { id: 'Trazado_109', "data-name": 'Trazado 109', d: 'M328.575,1.83v7.3c0,.98-1.887.981-1.887,0V1.83C326.687.85,328.575.85,328.575,1.83Z', transform: 'translate(-320.384 -1.095)', fill: colorTwo ? colorTwo : '#e9e9ed', fillRule: 'evenodd' })] })));
};
export default TagIcon;
