var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var ProhibitedIcon = function (_a) {
    var color = _a.color;
    return (_jsx("svg", __assign({ xmlns: 'http://www.w3.org/2000/svg', width: '1em', height: '1em', viewBox: '0 0 23.426 23.426' }, { children: _jsx("g", __assign({ id: 'noun-prohibited-892600', transform: 'translate(-98 -28)' }, { children: _jsx("path", { id: 'Trazado_159', "data-name": 'Trazado 159', d: 'M109.713,28a11.713,11.713,0,1,0,11.713,11.713A11.73,11.73,0,0,0,109.713,28Zm0,2.6a8.943,8.943,0,0,1,5.44,1.822L102.4,45.179a9,9,0,0,1-1.822-5.44,9.164,9.164,0,0,1,9.136-9.136Zm0,18.22A8.943,8.943,0,0,1,104.273,47l12.754-12.754a9,9,0,0,1,1.822,5.44,9.164,9.164,0,0,1-9.136,9.136Z', fill: color ? color : '#b5b5c2' }) })) })));
};
export default ProhibitedIcon;
