var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { Select as _Select, Input as _Input } from 'antd';
import { useHistory } from 'react-router-dom';
import { Button } from '../styles/Button';
import SearchIcon from '../icons/SearchIcon';
import useDebounce from '../customHooks/useDebounce';
import React from 'react';
import { useTranslation } from 'react-i18next';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 16px 32px;\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-top: 1px solid ", ";\n  border-bottom: 1px solid ", ";\n"], ["\n  padding: 16px 32px;\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-top: 1px solid ", ";\n  border-bottom: 1px solid ", ";\n"])), function (props) { return props.theme.colors.white; }, function (props) { return props.theme.grayScale.gray2; }, function (props) { return props.theme.grayScale.gray2; });
var FilterGroup = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var FilterGroupTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-right: 16px;\n\n"], ["\n  margin-right: 16px;\n\n"])));
var Filters = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  gap: 16px;\n  display: flex;\n"], ["\n  gap: 16px;\n  display: flex;\n"])));
var Filter = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 160px;\n  > div {\n    width: 160px;\n  }\n"], ["\n  width: 160px;\n  > div {\n    width: 160px;\n  }\n"])));
var Input = styled(_Input)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background-color: ", " !important;\n  font-size: 12px;\n  height: 43px;\n  .ant-input{\n    font-weight: 600;\n    background-color: ", ";\n  }\n"], ["\n  background-color: ", " !important;\n  font-size: 12px;\n  height: 43px;\n  .ant-input{\n    font-weight: 600;\n    background-color: ", ";\n  }\n"])), function (props) { return props.theme.grayScale.gray8; }, function (props) { return props.theme.grayScale.gray8; });
var Select = styled(_Select)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-weight: 600;\n  font-size: 12px;\n  .ant-select-selector{\n    height: 43px !important;\n  }\n"], ["\n  font-weight: 600;\n  font-size: 12px;\n  .ant-select-selector{\n    height: 43px !important;\n  }\n"])));
var ActionsGroup = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n\n"], ["\n\n"])));
var Action = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n\n"], ["\n\n"])));
var UsersTableTools = function (_a) {
    var onFilters = _a.onFilters;
    var history = useHistory();
    var _b = React.useState(), searchBy = _b[0], setSearchBy = _b[1];
    var debouncedSearchBy = useDebounce(searchBy, 500);
    var t = useTranslation().t;
    React.useEffect(function () {
        handleFilters(debouncedSearchBy === null || debouncedSearchBy === void 0 ? void 0 : debouncedSearchBy.value, debouncedSearchBy === null || debouncedSearchBy === void 0 ? void 0 : debouncedSearchBy.key);
    }, [debouncedSearchBy]);
    var handleFilters = function (value, key) {
        onFilters(function (f) {
            var _a;
            return (__assign(__assign({}, f), (_a = {}, _a[key] = value, _a)));
        });
    };
    return (_jsxs(Wrapper, { children: [_jsx(FilterGroup, { children: _jsxs(Filters, { children: [_jsx(Filter, { children: _jsx(Input, { size: "large", suffix: _jsx(SearchIcon, { width: 15, color: '#90D990' }), placeholder: "".concat(t('general.placeholders.email')), onChange: function (e) { return setSearchBy({ value: e.target.value, key: 'userMail' }); } }) }), _jsx(Filter, { children: _jsxs(Select, __assign({ size: "large", placeholder: "".concat(t('general.placeholders.profile')), onChange: function (value) { return setSearchBy({ value: value, key: 'role' }); }, allowClear: true }, { children: [_jsx(Select.Option, __assign({ value: 'Manager' }, { children: t('general.selects.profile_select.manager') })), _jsx(Select.Option, __assign({ value: 'Supervisor' }, { children: t('general.selects.profile_select.supervisor') })), _jsx(Select.Option, __assign({ value: 'Mandated' }, { children: t('general.selects.profile_select.mandated') })), _jsx(Select.Option, __assign({ value: 'Operator' }, { children: t('general.selects.profile_select.operator') }))] })) }), _jsx(Filter, { children: _jsxs(Select, __assign({ size: "large", placeholder: "Estado", onChange: function (value) { return setSearchBy({ value: value, key: 'status' }); }, allowClear: true }, { children: [_jsx(Select.Option, __assign({ value: 'Enabled' }, { children: t('general.selects.status_select.enabled') })), _jsx(Select.Option, __assign({ value: 'Disabled' }, { children: t('general.selects.status_select.disabled') }))] })) })] }) }), _jsx(ActionsGroup, { children: _jsx(Action, { children: _jsx(Button, __assign({ size: 'large', type: 'primary', onClick: function () { return history.push('./users/create'); } }, { children: t('general.buttons.create_new_user') })) }) })] }));
};
export default UsersTableTools;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
