var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal as _Modal } from 'antd';
import styled from 'styled-components';
import CheckIcon from '../icons/CheckIcon';
import { Button } from '../styles/Button';
import ProhibitedIcon from '../icons/ProhibitedIcon';
import LoadingIcon from '../icons/LoadingIcon';
import { useTranslation } from 'react-i18next';
var Modal = styled(_Modal)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .ant-modal-footer{\n    padding: 0px !important;\n  }\n"], ["\n  .ant-modal-footer{\n    padding: 0px !important;\n  }\n"])));
var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 30px 40px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"], ["\n  padding: 30px 40px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"])));
var Icon = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50px;\n  width: 50px;\n  height: 50px;\n  background-color: ", ";\n  font-size: 26px;\n  line-height: 0;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 50px;\n  width: 50px;\n  height: 50px;\n  background-color: ", ";\n  font-size: 26px;\n  line-height: 0;\n"])), function (props) { return props.theme.colors.bg; });
var Title = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-weight: 500;\n  text-align: center;\n  margin-top: 0;\n"], ["\n  color: ", ";\n  font-weight: 500;\n  text-align: center;\n  margin-top: 0;\n"])), function (props) { return props.theme.grayScale.gray5; });
var ObjectText = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  font-weight: 700;\n  text-align: center;\n  margin-top: 16px;\n"], ["\n  color: ", ";\n  font-weight: 700;\n  text-align: center;\n  margin-top: 16px;\n"])), function (props) { return props.theme.grayScale.gray0; });
var ActionTitle = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: ", ";\n  font-weight: 300;\n  margin-top: 8px;\n  font-size: 16px;\n  text-align: center;\n  margin-top: 40px;\n"], ["\n  color: ", ";\n  font-weight: 300;\n  margin-top: 8px;\n  font-size: 16px;\n  text-align: center;\n  margin-top: 40px;\n"])), function (props) { return props.theme.grayScale.gray0; });
var ConfirmText = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  text-align: center;\n  font-weight: 700;\n  margin-top: 16px;\n  color: ", ";\n"], ["\n  text-align: center;\n  font-weight: 700;\n  margin-top: 16px;\n  color: ", ";\n"])), function (props) { return props.theme.grayScale.gray5; });
var Header = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding: 12px 0;\n  .title{\n    text-align: center;\n    font-size: 18px;\n    font-weight: 600;\n    color: ", ";\n  }\n"], ["\n  padding: 12px 0;\n  .title{\n    text-align: center;\n    font-size: 18px;\n    font-weight: 600;\n    color: ", ";\n  }\n"])), function (props) { return props.theme.grayScale.gray5; });
var Footer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 100%;\n  padding: 12px 0;\n  border-top: 1px solid ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 20px;\n\n  .cancel-btn{\n    text-decoration: underline;\n    cursor: pointer;\n    font-size: 12px;\n    font-weight: 600;\n    color: ", ";\n  }\n"], ["\n  width: 100%;\n  padding: 12px 0;\n  border-top: 1px solid ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 20px;\n\n  .cancel-btn{\n    text-decoration: underline;\n    cursor: pointer;\n    font-size: 12px;\n    font-weight: 600;\n    color: ", ";\n  }\n"])), function (props) { return props.theme.helpers.borderColorInput; }, function (props) { return props.theme.grayScale.gray5; });
function ModalHeader(_a) {
    var title = _a.title;
    return (_jsx(Header, { children: _jsx("div", __assign({ className: "title" }, { children: title })) }));
}
export var ModalFeedback = function (_a) {
    var title = _a.title, confirmTitle = _a.confirmTitle, object = _a.object, visible = _a.visible, icon = _a.icon, status = _a.status, _b = _a.keyName, keyName = _b === void 0 ? '' : _b, _c = _a.type, type = _c === void 0 ? "success" : _c, _d = _a.simple, simple = _d === void 0 ? true : _d, okBtnText = _a.okBtnText, loadingConfirm = _a.loadingConfirm, _e = _a.loadingSimpleBtn, loadingSimpleBtn = _e === void 0 ? false : _e, onOk = _a.onOk, onCancel = _a.onCancel;
    var t = useTranslation().t;
    return (_jsx(Modal, __assign({ title: type === 'confirm' ? _jsx(ModalHeader, { title: confirmTitle }) : null, className: 'feedback-modal', transitionName: '', maskTransitionName: '', centered: true, open: visible, destroyOnClose: true, width: type !== 'error' ? 550 : 300, closable: false, footer: _jsxs(Footer, { children: [onCancel !== undefined ?
                    _jsx("div", __assign({ className: 'cancel-btn', onClick: onCancel }, { children: t('general.buttons.cancel') }))
                    :
                        null, onOk !== undefined ?
                    _jsx(Button, __assign({ type: "primary", style: { width: 120 }, onClick: onOk, loading: loadingConfirm ? loadingConfirm : false }, { children: okBtnText ? okBtnText : t('general.buttons.accept') })) :
                    null] }) }, { children: _jsxs(Content, { children: [icon === 'check' ? _jsx(Icon, { children: _jsx(CheckIcon, { color: '#00D800' }) }) :
                    icon === 'disabled' ? _jsx(Icon, { children: _jsx(ProhibitedIcon, { color: '#00D800' }) }) :
                        icon === 'loading' ? _jsx(Icon, { children: _jsx(LoadingIcon, {}) }) : null, type === 'error' && _jsx(Icon, __assign({ style: { marginBottom: '20px' } }, { children: _jsx("span", __assign({ style: { color: '#EA3C3C', fontSize: '32px', fontWeight: '700' } }, { children: "!" })) })), _jsx(Title, { children: title }), _jsx(ObjectText, { children: object }), type === 'confirm' &&
                    _jsx(ConfirmText, { children: t('general.buttons.confirm_action') }), !simple &&
                    _jsx(ActionTitle, { children: "\u00BFQue desea hacer?" })] }) })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
