var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Button } from '../styles/Button';
import useDebounce from '../customHooks/useDebounce';
import React from 'react';
import { useTranslation } from 'react-i18next';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 16px 32px;\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-top: 1px solid ", ";\n  border-bottom: 1px solid ", ";\n"], ["\n  padding: 16px 32px;\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-top: 1px solid ", ";\n  border-bottom: 1px solid ", ";\n"])), function (props) { return props.theme.colors.white; }, function (props) { return props.theme.grayScale.gray2; }, function (props) { return props.theme.grayScale.gray2; });
var FilterGroup = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var ActionsGroup = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\n"], ["\n\n"])));
var Action = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\n"], ["\n\n"])));
var PrintersTableTools = function () {
    var history = useHistory();
    var _a = React.useState(), searchBy = _a[0], setSearchBy = _a[1];
    var debouncedSearchBy = useDebounce(searchBy, 500);
    var t = useTranslation().t;
    return (_jsxs(Wrapper, { children: [_jsx(FilterGroup, {}), _jsx(ActionsGroup, { children: _jsx(Action, { children: _jsx(Button, __assign({ style: { height: '44px' }, size: 'large', type: 'primary', onClick: function () { return history.push('./printers/create'); } }, { children: t('general.buttons.register_printer') })) }) })] }));
};
export default PrintersTableTools;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
