var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Select as _Select, DatePicker as _DatePicker, Input as _Input } from 'antd';
import { useHistory } from 'react-router-dom';
import SearchIcon from '../../icons/SearchIcon';
import useDebounce from '../../customHooks/useDebounce';
import { useTranslation } from 'react-i18next';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 16px 32px;\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-top: 1px solid ", ";\n  border-bottom: 1px solid ", ";\n"], ["\n  padding: 16px 32px;\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-top: 1px solid ", ";\n  border-bottom: 1px solid ", ";\n"])), function (props) { return props.theme.colors.white; }, function (props) { return props.theme.grayScale.gray2; }, function (props) { return props.theme.grayScale.gray2; });
var FilterGroup = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var FilterGroupTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-right: 16px;\n\n"], ["\n  margin-right: 16px;\n\n"])));
var Filters = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  gap: 16px;\n  display: flex;\n"], ["\n  gap: 16px;\n  display: flex;\n"])));
var Filter = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  /* width: 160px;\n  > div {\n    width: 160px;\n  } */\n"], ["\n  /* width: 160px;\n  > div {\n    width: 160px;\n  } */\n"])));
var Input = styled(_Input)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background-color: ", " !important;\n  font-size: 12px;\n  height: 43px;\n  .ant-input{\n    font-weight: 600;\n    background-color: ", ";\n  }\n"], ["\n  background-color: ", " !important;\n  font-size: 12px;\n  height: 43px;\n  .ant-input{\n    font-weight: 600;\n    background-color: ", ";\n  }\n"])), function (props) { return props.theme.grayScale.gray8; }, function (props) { return props.theme.grayScale.gray8; });
var Select = styled(_Select)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100%;\n  font-weight: 600;\n  font-size: 12px;\n  .ant-select-selector{\n    height: 43px !important;\n  }\n"], ["\n  width: 100%;\n  font-weight: 600;\n  font-size: 12px;\n  .ant-select-selector{\n    height: 43px !important;\n  }\n"])));
var RangePicker = styled(_DatePicker.RangePicker)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  \n  background-color: ", " !important;\n  height: 43px;\n  .ant-picker-input{\n    input{\n      font-size: 12px;\n    }\n  }\n  .ant-picker-suffix{\n    color: ", ";\n  }\n"], ["\n  \n  background-color: ", " !important;\n  height: 43px;\n  .ant-picker-input{\n    input{\n      font-size: 12px;\n    }\n  }\n  .ant-picker-suffix{\n    color: ", ";\n  }\n"])), function (props) { return props.theme.grayScale.gray8; }, function (props) { return props.theme.colors.primary; });
var PrintRecordTableTools = function (_a) {
    var printers = _a.printers, users = _a.users, filters = _a.filters, onSetFilters = _a.onSetFilters, onFetch = _a.onFetch;
    var history = useHistory();
    var _b = React.useState(''), searchBy = _b[0], setSearchBy = _b[1];
    var debouncedSearchBy = useDebounce(searchBy, 500);
    var t = useTranslation().t;
    useEffect(function () {
        onFetch(filters);
    }, [debouncedSearchBy]);
    var handleDateFilter = function (value) {
        onSetFilters(__assign(__assign({}, filters), { startDate: value[0]._d, endDate: value[1]._d }));
        setSearchBy(value);
    };
    var handleStockCode = function (value) {
        onSetFilters(__assign(__assign({}, filters), { stockCode: value }));
        setSearchBy(value);
    };
    var handleUserId = function (value) {
        onSetFilters(__assign(__assign({}, filters), { authorId: value }));
        setSearchBy(value);
    };
    var handlePrinterId = function (value) {
        onSetFilters(__assign(__assign({}, filters), { printerId: value }));
        setSearchBy(value);
    };
    var handleYard = function (value) {
        onSetFilters(__assign(__assign({}, filters), { yard: value }));
        setSearchBy(value);
    };
    return (_jsx(Wrapper, { children: _jsx(FilterGroup, { children: _jsxs(Filters, { children: [_jsx(Filter, { children: _jsx(RangePicker, { size: 'large', onChange: handleDateFilter, style: { width: 240 } }) }), _jsx(Filter, __assign({ style: { width: '140px' } }, { children: _jsx(Input, { size: "large", suffix: _jsx(SearchIcon, { width: 15, color: '#00D800' }), placeholder: "".concat(t('general.placeholders.stock_code_literal')), onChange: function (event) { return handleStockCode(event.target.value); } }) })), _jsx(Filter, __assign({ style: { width: '160px' } }, { children: _jsx(Select, __assign({ size: "large", placeholder: "".concat(t('general.placeholders.user')), onChange: handleUserId, showSearch: true, allowClear: true }, { children: users.map(function (u) { return (_jsx(Select.Option, __assign({ value: u._id }, { children: u.fullName }))); }) })) })), _jsx(Filter, __assign({ style: { width: '160px' } }, { children: _jsx(Select, __assign({ size: "large", placeholder: "".concat(t('general.placeholders.printer')), onChange: handlePrinterId, allowClear: true }, { children: printers.map(function (p) { return (_jsx(Select.Option, __assign({ value: p._id }, { children: p.name }))); }) })) })), _jsx(Filter, __assign({ style: { width: '120px' } }, { children: _jsx(Input, { size: "large", placeholder: "".concat(t('general.placeholders.yard')), onChange: function (event) { return handleYard(event.target.value); } }) }))] }) }) }));
};
export default PrintRecordTableTools;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
