var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var LogoutIcon = function (_a) {
    var colorOne = _a.colorOne, colorTwo = _a.colorTwo;
    return (_jsx("svg", __assign({ xmlns: 'http://www.w3.org/2000/svg', width: '1em', height: '1em', viewBox: '0 0 23.422 23.425' }, { children: _jsxs("g", __assign({ id: 'Grupo_938', "data-name": 'Grupo 938', transform: 'translate(-445.215 -545.503)' }, { children: [_jsx("path", { id: 'path_01', d: 'M102.588,22.207a2.432,2.432,0,0,0-1.219.258,2.305,2.305,0,0,0-1.315,2.064v.784h-3.9a2.357,2.357,0,0,0-2.347,2.341V30.77a2.362,2.362,0,0,0,2.347,2.347h3.9V33.9a2.375,2.375,0,0,0,3.754,1.879l6.249-4.687a2.344,2.344,0,0,0,0-3.746l-6.249-4.687a2.228,2.228,0,0,0-1.22-.449Z', transform: 'translate(357.646 527.998)', fill: colorOne ? colorOne : '#b5b5c2' }), _jsx("path", { id: 'path_02', "data-name": 'noun-logout-3669496', d: 'M90.092,17.51a2.366,2.366,0,0,0-2.523,2.331V38.589a2.348,2.348,0,0,0,2.41,2.34h.006a2.413,2.413,0,0,0,.668-.12l4.681-1.563a2.344,2.344,0,0,0,1.6-2.219V34.68h-.778a3.948,3.948,0,0,1-3.909-3.909V27.653a3.943,3.943,0,0,1,3.909-3.9h.778v-2.34a2.351,2.351,0,0,0-1.6-2.225l-4.681-1.563a2.284,2.284,0,0,0-.561-.111Z', transform: 'translate(357.646 527.998)', fill: colorTwo ? colorTwo : '#e9e9ed' })] })) })));
};
export default LogoutIcon;
