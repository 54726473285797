var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var DashboardIcon = function (_a) {
    var color = _a.color;
    return (_jsx("svg", __assign({ xmlns: 'http://www.w3.org/2000/svg', width: '1em', height: '1em', viewBox: '0 0 23 23' }, { children: _jsxs("g", __assign({ id: 'Grupo_911', "data-name": 'Grupo 911', transform: 'translate(432.323 568.533) rotate(180)' }, { children: [_jsx("rect", { id: 'Rect\u00E1ngulo_303', "data-name": 'Rect\u00E1ngulo 303', width: '6', height: '8', rx: '3', transform: 'translate(426.323 545.533)', fill: color ? color : '#b5b5c2' }), _jsx("rect", { id: 'Rect\u00E1ngulo_304', "data-name": 'Rect\u00E1ngulo 304', width: '6', height: '17', rx: '3', transform: 'translate(418.323 545.533)', fill: color ? color : '#b5b5c2' }), _jsx("rect", { id: 'Rect\u00E1ngulo_305', "data-name": 'Rect\u00E1ngulo 305', width: '6', height: '23', rx: '3', transform: 'translate(409.323 545.533)', fill: color ? color : '#b5b5c2' })] })) })));
};
export default DashboardIcon;
