var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { ContainerTableFullH, TableDashboardPrints } from '../../styles/Table';
import { Select as _Select, Input as _Input } from 'antd';
import styled from 'styled-components';
import UserIcon from '../../icons/UserIcon';
import { useTranslation } from 'react-i18next';
var ModalContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  padding: 0 30px;\n  display: flex;\n  flex-direction: column;\n  font-size: 12px;\n\n  .modal-content-top, .modal-content-bottom{\n    display: grid;\n    grid-template-columns: 3fr 2fr;\n    align-items: center;\n    padding: 20px 0;\n  }\n  .modal-content-top{\n    .text{\n      color: ", ";\n    }\n  }\n  .modal-content-bottom{\n    border-top: 1px solid ", ";\n  }\n"], ["\n  width: 100%;\n  padding: 0 30px;\n  display: flex;\n  flex-direction: column;\n  font-size: 12px;\n\n  .modal-content-top, .modal-content-bottom{\n    display: grid;\n    grid-template-columns: 3fr 2fr;\n    align-items: center;\n    padding: 20px 0;\n  }\n  .modal-content-top{\n    .text{\n      color: ", ";\n    }\n  }\n  .modal-content-bottom{\n    border-top: 1px solid ", ";\n  }\n"])), function (props) { return props.theme.grayScale.gray5; }, function (props) { return props.theme.helpers.borderColor; });
var Select = styled(_Select)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 60px;\n  font-size: 12px;\n  font-weight: 500;\n  .ant-select-arrow{\n    color: ", ";\n  }\n"], ["\n  width: 60px;\n  font-size: 12px;\n  font-weight: 500;\n  .ant-select-arrow{\n    color: ", ";\n  }\n"])), function (props) { return props.theme.colors.primary; });
var Input = styled(_Input)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 12px;\n  background-color: ", ";\n  height: 34px;\n  text-align: center;\n\n  &.input-active{\n    border: 1px solid ", ";\n  }\n"], ["\n  font-size: 12px;\n  background-color: ", ";\n  height: 34px;\n  text-align: center;\n\n  &.input-active{\n    border: 1px solid ", ";\n  }\n"])), function (props) { return props.theme.grayScale.gray8; }, function (props) { return props.theme.grayScale.gray1; });
var LeftContent = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  \n  margin: 10px 0;\n  padding: 14px 0 14px 14px;\n  background-color: white;\n  border: 1px solid ", ";\n  border-right: none;\n  border-radius: 12px 0 0 12px;\n  display: flex;\n  align-items: center;\n  gap: 20px;\n\n  .icon{\n    width: 35px;\n    height: 35px;\n    background-color: ", ";\n    border-radius: 50%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-size: 17px;\n    line-height: 0;\n  }\n  .content{\n    display: flex;\n    align-items: center;\n    gap: 6px;\n    &-dot{\n      width: 10px;\n      height: 10px;\n      background-color: ", ";\n      border-radius: 50%;\n    }\n  }\n"], ["\n  \n  margin: 10px 0;\n  padding: 14px 0 14px 14px;\n  background-color: white;\n  border: 1px solid ", ";\n  border-right: none;\n  border-radius: 12px 0 0 12px;\n  display: flex;\n  align-items: center;\n  gap: 20px;\n\n  .icon{\n    width: 35px;\n    height: 35px;\n    background-color: ", ";\n    border-radius: 50%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-size: 17px;\n    line-height: 0;\n  }\n  .content{\n    display: flex;\n    align-items: center;\n    gap: 6px;\n    &-dot{\n      width: 10px;\n      height: 10px;\n      background-color: ", ";\n      border-radius: 50%;\n    }\n  }\n"])), function (props) { return props.theme.helpers.borderColorInput; }, function (props) { return props.theme.grayScale.gray3; }, function (props) { return props.theme.colors.purple; });
var MiddleContent = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  height: 65px;\n  margin: 10px 0;\n  padding: 14px 0;\n  background-color: white;\n  border: 1px solid ", ";\n  border-right: none;\n  border-left: none;\n  display: flex;\n  align-items: center;\n  /* justify-content: center; */\n\n  .box{\n    background-color: ", ";\n    color: ", ";\n    font-weight: 600;\n    border-radius: 6px;\n    padding: 6px 34px;\n    text-align: center;\n  }\n"], ["\n  height: 65px;\n  margin: 10px 0;\n  padding: 14px 0;\n  background-color: white;\n  border: 1px solid ", ";\n  border-right: none;\n  border-left: none;\n  display: flex;\n  align-items: center;\n  /* justify-content: center; */\n\n  .box{\n    background-color: ", ";\n    color: ", ";\n    font-weight: 600;\n    border-radius: 6px;\n    padding: 6px 34px;\n    text-align: center;\n  }\n"])), function (props) { return props.theme.helpers.borderColorInput; }, function (props) { return props.theme.colors.primary; }, function (props) { return props.theme.colors.white; });
var RightContent = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  height: 65px;\n  margin: 10px 0;\n  padding: 14px 48px 14px 14px;\n  background-color: ", ";\n  border: 1px solid ", ";\n  border-left: none;\n  border-radius: 0 12px 12px 0;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n\n  .box{\n    background-color: ", ";\n    color: ", ";\n    font-weight: 600;\n    border-radius: 6px;\n    padding: 6px 24px;\n    text-align: center;\n    margin-left: 60px;\n  }\n"], ["\n  height: 65px;\n  margin: 10px 0;\n  padding: 14px 48px 14px 14px;\n  background-color: ", ";\n  border: 1px solid ", ";\n  border-left: none;\n  border-radius: 0 12px 12px 0;\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n\n  .box{\n    background-color: ", ";\n    color: ", ";\n    font-weight: 600;\n    border-radius: 6px;\n    padding: 6px 24px;\n    text-align: center;\n    margin-left: 60px;\n  }\n"])), function (props) { return props.theme.colors.white; }, function (props) { return props.theme.helpers.borderColorInput; }, function (props) { return props.theme.grayScale.gray5; }, function (props) { return props.theme.colors.white; });
var DashboardPrintsTable = function (_a) {
    var loading = _a.loading, data = _a.data;
    var _b = useState({
        current_page: 1,
        last_page: 1,
        per_page: 20,
        total: 1000,
        total_results: 1000
    }), meta = _b[0], setMeta = _b[1];
    var t = useTranslation().t;
    var columns = [
        {
            title: 'Usuario',
            dataIndex: '_id',
            key: '_id',
            width: '40%',
            render: function (val) {
                var _a;
                return (_jsxs(LeftContent, { children: [_jsx("div", __assign({ className: "icon" }, { children: _jsx(UserIcon, {}) })), _jsxs("div", __assign({ className: "content" }, { children: [_jsx("div", { className: "content-dot" }), _jsx("div", __assign({ className: "content-text" }, { children: val ? (_a = val.author) === null || _a === void 0 ? void 0 : _a.fullName : '-' }))] }))] }));
            }
        },
        {
            title: 'Bodega',
            dataIndex: '_id',
            key: '_id',
            render: function (val) {
                return (_jsx(MiddleContent, { children: _jsx("div", __assign({ className: "box" }, { children: val ? val.storageBinId : '-' })) }));
            }
        },
        {
            title: 'Cantidad',
            dataIndex: 'total',
            key: 'total',
            render: function (val) {
                return (_jsx(RightContent, { children: _jsx("div", __assign({ className: "box" }, { children: val ? val : '-' })) }));
            }
        }
    ];
    return (_jsx(ContainerTableFullH, { children: _jsx(TableDashboardPrints, { columns: columns, dataSource: data, 
            // onChange={handleOnChange}
            loading: loading, locale: {
                emptyText: 'No hay datos',
            }, scroll: { y: '100%' }, pagination: {
                size: 'small',
                showTotal: function (total, range) { return (_jsxs("div", __assign({ className: 'pagination-content' }, { children: [_jsxs(Select, __assign({ defaultValue: 20, size: 'small', onChange: function (value) { return setMeta({
                                current_page: meta === null || meta === void 0 ? void 0 : meta.current_page,
                                last_page: meta === null || meta === void 0 ? void 0 : meta.last_page,
                                per_page: value,
                                total: meta === null || meta === void 0 ? void 0 : meta.total,
                                total_results: meta === null || meta === void 0 ? void 0 : meta.total_results
                            }); } }, { children: [_jsx(Select.Option, __assign({ value: 10 }, { children: "10" })), _jsx(Select.Option, __assign({ value: 20 }, { children: "20" })), _jsx(Select.Option, __assign({ value: 50 }, { children: "50" }))] })), _jsx("span", { children: "".concat(range[0], "-").concat(range[1], " ").concat(t('general.labels.of'), " ").concat(data.length, " ").concat(t('general.labels.results')) })] }))); },
                pageSize: meta === null || meta === void 0 ? void 0 : meta.per_page,
                // total: meta?.total_results,
                // current: meta?.current_page,
                showSizeChanger: false
            }, rowKey: "_id", showSorterTooltip: false }) }));
};
export default DashboardPrintsTable;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
