var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var PasswordIcon = function (_a) {
    var color = _a.color;
    return (_jsx("svg", __assign({ xmlns: 'http://www.w3.org/2000/svg', width: '0.80em', height: '1em', viewBox: '0 0 18.74 23.425' }, { children: _jsx("path", { id: 'noun-lock-5326865', d: 'M178.557,54.861V52.519a5.856,5.856,0,1,0-11.713,0v2.343a3.451,3.451,0,0,0-3.514,3.514v8.2a3.451,3.451,0,0,0,3.514,3.514h11.712a3.451,3.451,0,0,0,3.514-3.514v-8.2a3.451,3.451,0,0,0-3.514-3.514Zm-9.37-2.343a3.514,3.514,0,1,1,7.028,0v2.343h-7.028Zm4.8,9.956-.117.117v1.64a1.171,1.171,0,0,1-2.342,0v-1.64a1.741,1.741,0,1,1,2.46-.117Z', transform: 'translate(-163.33 -46.662)', fill: color ? color : '#b5b5c2' }) })));
};
export default PasswordIcon;
