var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql, useLazyQuery } from "@apollo/client";
var GET_PROFILE = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query profile {\n    profile{\n      _id\n      companyCode\n      createdAt\n      fullName\n      isNewUser\n      plantCode\n      position\n      lastLogin\n      role\n      status\n      userMail\n      username\n    }\n  }\n"], ["\n  query profile {\n    profile{\n      _id\n      companyCode\n      createdAt\n      fullName\n      isNewUser\n      plantCode\n      position\n      lastLogin\n      role\n      status\n      userMail\n      username\n    }\n  }\n"])));
export var useGetProfile = function () {
    var getProfile = useLazyQuery(GET_PROFILE, { fetchPolicy: "no-cache", context: { clientName: 'authPath' } })[0];
    return [
        getProfile
    ];
};
var templateObject_1;
