import { jsx as _jsx } from "react/jsx-runtime";
import { Switch, Route } from 'react-router-dom';
import DashboardPrints from './DashboardPrints';
// import UserCreate from './UserCreate'
// import UserEdit from './UserEdit'
var DashboardPrintsRouter = function () {
    var mainPath = '/dashboard/prints';
    return (_jsx(Switch, { children: _jsx(Route, { path: mainPath, children: _jsx(DashboardPrints, {}) }) }));
};
export default DashboardPrintsRouter;
