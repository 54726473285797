var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql, useLazyQuery } from "@apollo/client";
var GET_PRINTERS = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query PrintersFindAll {\n    printersFindAll{\n      _id\n      code\n      controlStock\n      currentStock\n      lastPurchaseDate\n      lastPurchaseQuantity\n      name\n      status\n      storageBinId\n    }\n  }\n"], ["\n  query PrintersFindAll {\n    printersFindAll{\n      _id\n      code\n      controlStock\n      currentStock\n      lastPurchaseDate\n      lastPurchaseQuantity\n      name\n      status\n      storageBinId\n    }\n  }\n"])));
export var useGetPrinters = function () {
    var _a = useLazyQuery(GET_PRINTERS, { context: { clientName: 'printPath' } }), getPrinters = _a[0], _b = _a[1], loading = _b.loading, error = _b.error, data = _b.data;
    return [
        getPrinters,
        loading,
        error,
        data
    ];
};
var templateObject_1;
