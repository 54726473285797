var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql, useLazyQuery } from "@apollo/client";
export var GET_USERS = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query findAll($limit: Float!, $offset: Float!, $role:Role, $status: Status, $userMail:String) {\n    findAll(limit: $limit, offset: $offset, role: $role, status: $status, userMail: $userMail) {\n      _id\n      companyCode\n      createdAt\n      fullName\n      isNewUser\n      lastLogin\n      plantCode\n      position\n      role\n      status\n      userMail\n      username\n    }\n  }\n"], ["\n  query findAll($limit: Float!, $offset: Float!, $role:Role, $status: Status, $userMail:String) {\n    findAll(limit: $limit, offset: $offset, role: $role, status: $status, userMail: $userMail) {\n      _id\n      companyCode\n      createdAt\n      fullName\n      isNewUser\n      lastLogin\n      plantCode\n      position\n      role\n      status\n      userMail\n      username\n    }\n  }\n"])));
export var useGetUsers = function () {
    var _a = useLazyQuery(GET_USERS, { context: { clientName: 'authPath' } }), getUsers = _a[0], _b = _a[1], loading = _b.loading, error = _b.error, data = _b.data;
    return [getUsers, loading, error, data];
};
var templateObject_1;
