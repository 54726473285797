var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import UsersTable from './UsersTable';
import AppTopBar from '../app/AppTopBar';
import { Wrapper, ScrollContainer } from '../styles/Section';
import UsersTableTools from './UsersTableTools';
import { useGetUsers } from '../graphql/users/useGetUsers';
import { ModalFeedback } from '../components/ModalFeedback';
import { useTranslation } from 'react-i18next';
var Users = function () {
    var _a = useState({ show: false }), error = _a[0], setError = _a[1];
    var _b = useState({ limit: 20, offset: 0 }), filters = _b[0], setFilters = _b[1];
    var history = useHistory();
    var t = useTranslation().t;
    var _c = useGetUsers(), getUsers = _c[0], loading = _c[1], usersError = _c[2], data = _c[3];
    useEffect(function () {
        getUsers({ variables: filters });
    }, [filters]);
    useEffect(function () {
        if (usersError) {
            setError({ show: true, message: usersError.graphQLErrors[0].message });
        }
    }, [usersError]);
    return (_jsxs(Wrapper, { children: [_jsx(AppTopBar, { sectionName: t('main_menu.users') }), _jsxs(ScrollContainer, { children: [_jsx(UsersTableTools, { onFilters: setFilters }), _jsx(UsersTable, { loading: loading, data: data && __spreadArray([], data === null || data === void 0 ? void 0 : data.findAll, true).reverse(), filters: filters, onFilters: setFilters })] }), _jsx(ModalFeedback, { title: error.message, visible: error.show, type: 'error', icon: '', simple: true, okBtnText: 'cerrar', onOk: function () { return setError({ show: false, message: '' }); } })] }));
};
export default Users;
