var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ContainerTableFullH, TableFullH, TableActions, TableAction } from '../../styles/Table';
import { Select as _Select, Input as _Input } from 'antd';
import moment from 'moment';
import { ModalFeedback } from '../../components/ModalFeedback';
import styled from 'styled-components';
import { ModalTable } from '../../components/ModalTable';
import PrintFileIcon from '../../icons/PrintFileIcon';
import { usePrintsCreate } from '../../graphql/print/usePrintsCreate';
import { useGetHistoryCount } from '../../graphql/print/useGetHistoryCount';
import { useTranslation } from 'react-i18next';
var ModalContent = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  padding: 0 30px;\n  display: flex;\n  flex-direction: column;\n  font-size: 12px;\n\n  .modal-content-top, .modal-content-bottom{\n    display: grid;\n    grid-template-columns: 3fr 2fr;\n    align-items: center;\n    padding: 20px 0;\n  }\n  .modal-content-top{\n    .text{\n      color: ", ";\n    }\n  }\n  .modal-content-bottom{\n    border-top: 1px solid ", ";\n  }\n"], ["\n  width: 100%;\n  padding: 0 30px;\n  display: flex;\n  flex-direction: column;\n  font-size: 12px;\n\n  .modal-content-top, .modal-content-bottom{\n    display: grid;\n    grid-template-columns: 3fr 2fr;\n    align-items: center;\n    padding: 20px 0;\n  }\n  .modal-content-top{\n    .text{\n      color: ", ";\n    }\n  }\n  .modal-content-bottom{\n    border-top: 1px solid ", ";\n  }\n"])), function (props) { return props.theme.grayScale.gray5; }, function (props) { return props.theme.helpers.borderColor; });
var Select = styled(_Select)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 60px;\n  font-size: 12px;\n  font-weight: 500;\n  .ant-select-arrow{\n    color: ", ";\n  }\n"], ["\n  width: 60px;\n  font-size: 12px;\n  font-weight: 500;\n  .ant-select-arrow{\n    color: ", ";\n  }\n"])), function (props) { return props.theme.colors.primary; });
var Input = styled(_Input)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 12px;\n  background-color: ", ";\n  height: 45px;\n  text-align: center;\n\n  &.input-active{\n    border: 1px solid ", ";\n  }\n"], ["\n  font-size: 12px;\n  background-color: ", ";\n  height: 45px;\n  text-align: center;\n\n  &.input-active{\n    border: 1px solid ", ";\n  }\n"])), function (props) { return props.theme.grayScale.gray8; }, function (props) { return props.theme.grayScale.gray1; });
var PrintRecodTable = function (_a) {
    var loading = _a.loading, data = _a.data, filters = _a.filters, onSetFilters = _a.onSetFilters;
    var history = useHistory();
    var _b = useState(undefined), tagToPrint = _b[0], setTagToPrint = _b[1];
    var _c = useState({}), error = _c[0], setError = _c[1];
    var _d = useState({
        per_page: filters.limit,
        current_page: (filters.offset / filters.limit) + 1,
        total: 0,
    }), meta = _d[0], setMeta = _d[1];
    var _e = useState(undefined), currentModal = _e[0], setCurrentModal = _e[1];
    var t = useTranslation().t;
    var _f = usePrintsCreate(), createPrint = _f[0], printLoading = _f[1];
    var totalHistory = useGetHistoryCount().data;
    useEffect(function () {
        console.log({ totalHistory: totalHistory });
        if (totalHistory) {
            setMeta(__assign(__assign({}, meta), { total: totalHistory.historyCount }));
        }
    }, [totalHistory]);
    var handleOnChange = function (pagination) {
        if ((filters.offset / filters.limit) + 1 !== pagination.current) {
            onSetFilters(function (f) { return (__assign(__assign({}, f), { offset: (pagination.current - 1) * filters.limit })); });
            setMeta(__assign(__assign({}, meta), { current_page: pagination.current }));
        }
    };
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var print_1, response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log({ tagToPrint: tagToPrint });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    print_1 = {
                        storageLocation: tagToPrint.storageLocation,
                        description: tagToPrint.description,
                        origin: tagToPrint.origin,
                        printerId: tagToPrint.printerId,
                        purchaseOrder: tagToPrint.purchaseOrder,
                        quantity: tagToPrint.quantity,
                        stockCode: tagToPrint.stockCode,
                        storageBin: tagToPrint.storageBin,
                        valuationType: tagToPrint.valuationType
                    };
                    return [4 /*yield*/, createPrint({ variables: { createPrintDtos: [print_1] } })];
                case 2:
                    response = _a.sent();
                    setCurrentModal(2);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.log({ error: error_1 });
                    setCurrentModal(3);
                    setError({ message: error_1.graphQLErrors[0].message });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var columns = [
        {
            title: 'Usuario',
            dataIndex: 'author',
            key: 'author',
            // width: '14%',
            render: function (val) { return val ? val.username : '-'; }
        },
        {
            title: 'Fecha de impresión',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: function (val) { return val === null ? '-' : moment(val, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY'); }
            // width: '28%'
        },
        {
            title: 'Stock code',
            dataIndex: 'stockCode',
            key: 'stockCode',
            // width: '14%',
            render: function (val) { return val ? val : '-'; }
        },
        {
            title: 'Descripción',
            dataIndex: 'description',
            key: 'description',
            width: '18%',
            render: function (val) { return val ? val : '-'; }
        },
        {
            title: 'Orden de compra',
            dataIndex: 'purchaseOrder',
            key: 'purchaseOrder',
            // width: '14%',
            render: function (val) { return val ? val : '-'; }
        },
        {
            title: 'Storage bin',
            dataIndex: 'storageBin',
            key: 'storageBin',
            // width: '14%',
            render: function (val) { return val ? val : '-'; }
        },
        {
            title: 'Valuation type',
            dataIndex: 'valuationType',
            key: 'valuationType',
            // width: '14%',
            render: function (val) { return val ? val : '-'; }
        },
        {
            title: 'Cantidad',
            dataIndex: 'quantity',
            key: 'quantity',
            // width: '14%',
            render: function (val) { return val ? val : '-'; }
        },
        {
            title: 'Origen',
            dataIndex: 'origin',
            key: 'origin',
            // width: '14%',
            render: function (val) { return val ? val : '-'; }
        },
        {
            title: 'Imprimir',
            dataIndex: 'actions',
            key: 'actions',
            // width: '10%',
            align: 'center',
            render: function (val, record) { return (_jsx(TableActions, { children: _jsx(TableAction, __assign({ onClick: function () {
                        setTagToPrint(record);
                        setCurrentModal(1);
                    } }, { children: _jsx(PrintFileIcon, {}) })) })); }
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(ContainerTableFullH, { children: _jsx(TableFullH, { columns: columns, dataSource: data, onChange: handleOnChange, loading: loading, rowClassName: function (record, index) { return index % 2 === 0 ? 'table-row-light' : 'table-row-dark'; }, locale: {
                        emptyText: 'No hay datos',
                    }, scroll: { y: '100%' }, pagination: {
                        size: 'small',
                        showTotal: function (total, range) { return (_jsxs("div", __assign({ className: 'pagination-content' }, { children: [_jsxs(Select, __assign({ defaultValue: 20, size: 'small', onChange: function (value) {
                                        setMeta({
                                            per_page: value,
                                            current_page: 1,
                                            total: meta === null || meta === void 0 ? void 0 : meta.total,
                                        });
                                        onSetFilters(function (filters) { return (__assign(__assign({}, filters), { limit: value, offset: 0 })); });
                                    } }, { children: [_jsx(Select.Option, __assign({ value: 10 }, { children: "10" })), _jsx(Select.Option, __assign({ value: 20 }, { children: "20" })), _jsx(Select.Option, __assign({ value: 50 }, { children: "50" }))] })), _jsx("span", { children: "".concat(range[0], "-").concat(range[1], " ").concat(t('general.labels.of'), " ").concat(meta === null || meta === void 0 ? void 0 : meta.total, " ").concat(t('general.labels.results')) })] }))); },
                        pageSize: filters.limit,
                        total: meta === null || meta === void 0 ? void 0 : meta.total,
                        current: meta === null || meta === void 0 ? void 0 : meta.current_page,
                        showSizeChanger: false
                    }, rowKey: "_id", showSorterTooltip: false }) }), _jsx(ModalTable, { type: 'simple', width: 420, title: 'Ingresar cantidad', visible: currentModal === 1, onOk: handleSubmit, onCancel: function () { return setCurrentModal(undefined); }, loadingConfirm: printLoading, simpleTextbtn: 'Imprimir', table: _jsxs(ModalContent, { children: [_jsxs("div", __assign({ className: "modal-content-top" }, { children: [_jsx("div", __assign({ className: "text" }, { children: "stockCode" })), _jsx("div", __assign({ className: "text" }, { children: "Cantidad" }))] })), _jsxs("div", __assign({ className: "modal-content-bottom" }, { children: [_jsx("div", __assign({ className: "value" }, { children: tagToPrint === null || tagToPrint === void 0 ? void 0 : tagToPrint.stockCode })), _jsx(Input, { size: 'large', placeholder: '0', onChange: function (e) { return setTagToPrint(function (tag) { return (__assign(__assign({}, tag), { quantity: parseInt(e.target.value) })); }); } })] }))] }) }), _jsx(ModalFeedback, { title: _jsxs("div", __assign({ style: { marginTop: '20px', maxWidth: '444px', } }, { children: ["Los materiales se han enviado a imprimir de forma exitosa, puedes revisar el estado de la impresi\u00F3n en el ", _jsx("span", __assign({ style: { color: '#000', fontWeight: '700' } }, { children: "\"Historial de impresiones\"" }))] })), icon: 'check', visible: currentModal === 2, onOk: function () {
                    setCurrentModal(undefined);
                    setTagToPrint(undefined);
                } }), _jsx(ModalFeedback, { title: error.message, visible: currentModal === 3, type: 'error', icon: '', simple: true, okBtnText: 'cerrar', onOk: function () { return setCurrentModal(1); } })] }));
};
export default PrintRecodTable;
var templateObject_1, templateObject_2, templateObject_3;
