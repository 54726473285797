var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var SearchIcon = function (_a) {
    var color = _a.color, width = _a.width;
    return (_jsx("svg", __assign({ xmlns: 'http://www.w3.org/2000/svg', width: width ? width : '1em', height: width ? width : '1em', viewBox: '0 0 14.873 14.869' }, { children: _jsx("g", __assign({ id: 'noun-search-4648873', transform: 'translate(-182.064 -111.996)' }, { children: _jsx("path", { id: 'Trazado_90', "data-name": 'Trazado 90', d: 'M188.381,112a6.319,6.319,0,1,0,3.717,11.431l3.147,3.147a.992.992,0,1,0,1.4-1.4l-3.147-3.147A6.314,6.314,0,0,0,188.381,112Zm0,10.655a4.342,4.342,0,1,1,3.065-1.272A4.336,4.336,0,0,1,188.381,122.651Z', transform: 'translate(0 0)', fill: color ? color : '#b5b5c2' }) })) })));
};
export default SearchIcon;
