var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import logo from '../../../assets/images/login/logoIcon.svg';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  img{\n    width: 86px;\n  }\n  .text-one{\n    font-size: 18px;\n    font-weight: 700;\n    color: ", ";\n    margin-top: 12px;\n  }\n  .text-two{\n    font-size: 18px;\n    font-weight: 700;\n    color: ", ";\n  }\n"], ["\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  img{\n    width: 86px;\n  }\n  .text-one{\n    font-size: 18px;\n    font-weight: 700;\n    color: ", ";\n    margin-top: 12px;\n  }\n  .text-two{\n    font-size: 18px;\n    font-weight: 700;\n    color: ", ";\n  }\n"])), function (props) { return props.theme.grayScale.gray0; }, function (props) { return props.theme.grayScale.gray5; });
var PrintBulkLoader = function () {
    return (_jsxs(Wrapper, { children: [_jsx("img", { src: logo, alt: "smartbot" }), _jsx("span", __assign({ className: 'text-one' }, { children: "Realizando" })), _jsx("span", __assign({ className: 'text-two' }, { children: "Carga de datos" }))] }));
};
export default PrintBulkLoader;
var templateObject_1;
