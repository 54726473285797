var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SET_AUTH, SET_PRINTS, SET_SELECTED_PRINTER, SET_TAGS_TO_PRINT, SET_PRINT_TYPE, SET_TOTAL_TO_PRINT, SET_LNG } from "./AppContext";
export default (function (state, action) {
    switch (action.type) {
        case SET_AUTH:
            return __assign(__assign({}, state), { isAuthenticated: action.payload });
        case SET_PRINTS:
            return __assign(__assign({}, state), { prints: action.payload });
        case SET_SELECTED_PRINTER:
            return __assign(__assign({}, state), { selectedPrinter: action.payload });
        case SET_TAGS_TO_PRINT:
            return __assign(__assign({}, state), { tagsToPrint: action.payload });
        case SET_PRINT_TYPE:
            return __assign(__assign({}, state), { printType: action.payload });
        case SET_TOTAL_TO_PRINT:
            return __assign(__assign({}, state), { totalToPrint: action.payload });
        case SET_LNG:
            return __assign(__assign({}, state), { lng: action.payload });
        default:
            return state;
    }
});
