var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import DashboardRouter from '../dashboard/DashboardRouter';
import { useGetProfile } from '../graphql/useGetProfile';
import PrintRouter from '../print/PrintRouter';
import PrintersRouter from '../printers/PrintersRooter';
import SupportRouter from '../support/SupportRouter';
import TagsRouter from '../tags/TagsRouter';
import UpdatesRouter from '../updates/UpdatesRouter';
import UsersRouter from '../users/UsersRouter';
import AppCreatePassword from './AppCreatePassword';
import AppSidebar from './AppSidebar';
import io from 'socket.io-client';
import { API_PATH } from '../constants/api';
var AppMainLayout = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  height: 100%;\n"], ["\n  display: flex;\n  flex-direction: row;\n  height: 100%;\n"])));
var AppContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: auto;\n  min-height: 0;\n  width: 50%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: auto;\n  min-height: 0;\n  width: 50%;\n"])));
var Wrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  overflow: auto;\n  background-color: ", ";\n"], ["\n  width: 100%;\n  height: 100%;\n  overflow: auto;\n  background-color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.bg;
});
function App() {
    var _a = useState(undefined), user = _a[0], setUser = _a[1];
    var _b = useState(), socket = _b[0], setSocket = _b[1];
    var history = useHistory();
    var location = useLocation();
    var getUser = useGetProfile()[0];
    useEffect(function () {
        var s = io(API_PATH, {
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: 5,
        });
        setSocket(s);
    }, []);
    useEffect(function () {
        getUser().then(function (response) {
            setUser(response.data.profile);
        }).catch(function (error) {
            console.log({ error: error });
        });
    }, []);
    useEffect(function () {
        if (user && user.isNewUser !== null && location.pathname === '/') {
            history.push('/print/manual');
        }
    }, [user]);
    if ((user === null || user === void 0 ? void 0 : user.isNewUser) === null) {
        return (_jsx(AppCreatePassword, {}));
    }
    else {
        return (_jsxs(AppMainLayout, { children: [_jsx(AppSidebar, {}), _jsx(AppContent, { children: _jsx(Wrapper, { children: _jsxs(Switch, { children: [_jsx(Route, { path: "/print", children: _jsx(PrintRouter, { user: user }) }), _jsx(Route, { path: "/tags", children: _jsx(TagsRouter, { user: user }) }), _jsx(Route, { path: "/users", children: _jsx(UsersRouter, { user: user }) }), _jsx(Route, { path: "/updates", children: _jsx(UpdatesRouter, { user: user }) }), _jsx(Route, { path: "/dashboard", children: _jsx(DashboardRouter, { user: user }) }), _jsx(Route, { path: "/printers", children: _jsx(PrintersRouter, { user: user, socket: socket }) }), _jsx(Route, { path: "/support", children: _jsx(SupportRouter, { user: user }) })] }) }) })] }));
    }
}
export default App;
var templateObject_1, templateObject_2, templateObject_3;
