var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql, useLazyQuery } from "@apollo/client";
export var GET_WAREHOUSES = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query Warehouses{\n    warehouses{\n      companyCode\n      name\n      plantCode\n      storageBinId\n      storageLocation\n    }\n  }\n"], ["\n  query Warehouses{\n    warehouses{\n      companyCode\n      name\n      plantCode\n      storageBinId\n      storageLocation\n    }\n  }\n"])));
export var useGetWarehouses = function () {
    var _a = useLazyQuery(GET_WAREHOUSES, { context: { clientName: 'printPath' } }), getWarehouses = _a[0], _b = _a[1], loading = _b.loading, error = _b.error, data = _b.data;
    return [getWarehouses, loading, error, data];
};
var templateObject_1;
