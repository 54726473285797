var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ContainerTableFullH, TableFullH, TableActions, TableAction } from '../styles/Table';
import { Select as _Select, Tooltip } from 'antd';
import PencilIcon from '../icons/PencilIcon';
import { ModalFeedback } from '../components/ModalFeedback';
import { useUpdateUser } from '../graphql/users/useUpdateUser';
import styled from 'styled-components';
import PrinterEnabledIcon from '../icons/PrinterEnabledIcon';
import PrinterDisabledIcon from '../icons/PrinterDisabledIcon';
var Select = styled(_Select)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 60px;\n  font-size: 12px;\n  font-weight: 500;\n  .ant-select-arrow{\n    color: ", ";\n  }\n"], ["\n  width: 60px;\n  font-size: 12px;\n  font-weight: 500;\n  .ant-select-arrow{\n    color: ", ";\n  }\n"])), function (props) { return props.theme.colors.primary; });
var PrinterIconContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 38px;\n  height: 38px;\n  font-size: 24px;\n  line-height: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border: 1px solid ", ";\n  border-radius: ", ";\n"], ["\n  width: 38px;\n  height: 38px;\n  font-size: 24px;\n  line-height: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border: 1px solid ", ";\n  border-radius: ", ";\n"])), function (props) { return props.theme.helpers.borderColor; }, function (props) { return props.theme.borderRadiusTwo; });
var StatusTag = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-radius: ", ";\n  font-size: 12px;\n  font-weight: 600;\n  padding: 4px 14px;\n  width: fit-content;\n\n  &.disabled{\n    background-color: ", ";\n    color: ", ";\n  }\n  &.enabled{\n    background-color: ", ";\n    color: ", ";\n  }\n"], ["\n  border-radius: ", ";\n  font-size: 12px;\n  font-weight: 600;\n  padding: 4px 14px;\n  width: fit-content;\n\n  &.disabled{\n    background-color: ", ";\n    color: ", ";\n  }\n  &.enabled{\n    background-color: ", ";\n    color: ", ";\n  }\n"])), function (props) { return props.theme.borderRadiusTwo; }, function (props) { return props.theme.helpers.borderColor; }, function (props) { return props.theme.grayScale.gray6; }, function (props) { return props.theme.colors.green; }, function (props) { return props.theme.colors.white; });
var TooltipContent = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: 600;\n  font-size: 9px;\n  padding: 8px 6px;\n  .status{\n    font-size: 10px;\n    margin-bottom: 5px;\n    &.enabled{\n      color: ", ";\n    }\n    &.disabled{\n      color: ", ";\n    }\n  }\n  .updated{\n    margin-bottom: 5px;\n    &-title{\n      color: ", ";\n    }\n    &-time{\n      color: ", ";\n      margin-left: 4px;\n    }\n  }\n  .ready{\n    color: ", ";\n  }\n"], ["\n  font-weight: 600;\n  font-size: 9px;\n  padding: 8px 6px;\n  .status{\n    font-size: 10px;\n    margin-bottom: 5px;\n    &.enabled{\n      color: ", ";\n    }\n    &.disabled{\n      color: ", ";\n    }\n  }\n  .updated{\n    margin-bottom: 5px;\n    &-title{\n      color: ", ";\n    }\n    &-time{\n      color: ", ";\n      margin-left: 4px;\n    }\n  }\n  .ready{\n    color: ", ";\n  }\n"])), function (props) { return props.theme.colors.green; }, function (props) { return props.theme.colors.red2; }, function (props) { return props.theme.grayScale.gray1; }, function (props) { return props.theme.grayScale.gray5; }, function (props) { return props.theme.grayScale.gray5; });
var PrintersTable = function (_a) {
    var loading = _a.loading, data = _a.data, timeFromUpdate = _a.timeFromUpdate, onTimeUpdate = _a.onTimeUpdate;
    var history = useHistory();
    var _b = useState({
        per_page: 20,
        current_page: 1,
        total: 0,
    }), meta = _b[0], setMeta = _b[1];
    var _c = useState(false), openModal = _c[0], setOpenModal = _c[1];
    var _d = useState(false), openModalConfirm = _d[0], setOpenModalConfirm = _d[1];
    var _e = useState(undefined), user = _e[0], setUser = _e[1];
    var _f = useUpdateUser(), updateUser = _f[0], loadingUpdate = _f[1];
    // const handleOnChange = (pagination : any) => {
    //   if((filters.offset / filters.limit) + 1 !== pagination.current){
    //     onFilters((f: any) => ({...f, offset: (pagination.current - 1) * filters.limit}))
    //     setMeta({...meta, current_page: pagination.current})
    //   }
    // };
    var columns = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
            render: function (value) { return value; }
        },
        {
            title: 'Código de conexión',
            dataIndex: 'code',
            key: 'code',
            render: function (val) {
                return (_jsx("span", { children: val ? val : '-' }));
            }
        },
        {
            title: 'storageBinId',
            dataIndex: 'storageBinId',
            key: 'storageBinId',
            render: function (val) {
                return (_jsx("span", { children: val }));
            }
        },
        {
            title: 'Estado',
            dataIndex: 'status',
            key: 'status',
            render: function (val) {
                return (_jsx(_Fragment, { children: val === 'Enabled' ? _jsx(StatusTag, __assign({ className: 'enabled' }, { children: "Habilitada" })) : _jsx(StatusTag, __assign({ className: 'disabled' }, { children: "Deshabilitada" })) }));
            }
        },
        {
            title: 'Disponibilidad',
            dataIndex: 'available',
            key: 'available',
            align: 'center',
            render: function (val, record) {
                return (_jsx("div", __assign({ style: { display: 'flex', justifyContent: 'center' } }, { children: val ?
                        _jsx(Tooltip, __assign({ placement: 'rightTop', color: '#fff', title: _jsxs(TooltipContent, { children: [_jsxs("div", __assign({ className: "status enabled" }, { children: ["-", record.message] })), _jsxs("div", __assign({ className: "updated" }, { children: [_jsx("div", __assign({ className: "updated-title" }, { children: "-\u00DAltima actualizaci\u00F3n:" })), _jsx("div", __assign({ className: "updated-time" }, { children: 'Hace ' + timeFromUpdate }))] })), _jsx("div", __assign({ className: "ready" }, { children: "-Impresora disponible para imprimir" }))] }), onOpenChange: function (value) {
                                if (value) {
                                    var now = Date.now();
                                    var miliseconds = now - new Date(record.statusUpdate.date).getTime();
                                    var hours = miliseconds / 3600000;
                                    var minutes = (miliseconds % 3600000) / 60000;
                                    var seconds = ((miliseconds % 3600000) % 60000) / 1000;
                                    var time = "".concat(hours > 1 ? Math.floor(hours) + ' horas' : '', " ").concat(minutes > 1 ? Math.floor(minutes) + ' minutos' : '', " ").concat(Math.floor(seconds), " segundos");
                                    onTimeUpdate(time);
                                }
                            } }, { children: _jsx(PrinterIconContainer, { children: _jsx(PrinterEnabledIcon, {}) }) }))
                        :
                            _jsx(Tooltip, __assign({ placement: 'rightTop', color: '#fff', title: _jsxs(TooltipContent, { children: [_jsxs("div", __assign({ className: "status disabled" }, { children: ["-", record.message] })), _jsxs("div", __assign({ className: "updated" }, { children: [_jsx("div", __assign({ className: "updated-title" }, { children: "-\u00DAltima actualizaci\u00F3n:" })), _jsx("div", __assign({ className: "updated-time" }, { children: 'Hace ' + timeFromUpdate }))] })), _jsx("div", __assign({ className: "ready" }, { children: "-Impresora no disponible" }))] }), onOpenChange: function (value) {
                                    if (value) {
                                        var now = Date.now();
                                        var miliseconds = now - new Date(record.statusUpdate.date).getTime();
                                        var hours = miliseconds / 3600000;
                                        var minutes = (miliseconds % 3600000) / 60000;
                                        var seconds = ((miliseconds % 3600000) % 60000) / 1000;
                                        var time = "".concat(hours > 1 ? Math.floor(hours) + ' horas' : '', " ").concat(minutes > 1 ? Math.floor(minutes) + ' minutos' : '', " ").concat(Math.floor(seconds), " segundos");
                                        onTimeUpdate(time);
                                    }
                                } }, { children: _jsx(PrinterIconContainer, { children: _jsx(PrinterDisabledIcon, {}) }) })) })));
            }
        },
        {
            title: 'Acciones',
            dataIndex: 'actions',
            key: 'actions',
            align: 'center',
            render: function (val, record) { return (_jsx(TableActions, { children: _jsx(TableAction, __assign({ onClick: function () { return history.push({ pathname: "/printers/edit/printerId=".concat(record._id) }); } }, { children: _jsx(PencilIcon, { colorOne: '#00D800', colorTwo: '#40FF00' }) })) })); }
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(ContainerTableFullH, { children: _jsx(TableFullH, { columns: columns, dataSource: data, 
                    // onChange={handleOnChange}
                    loading: loading, locale: {
                        emptyText: 'No hay impresoras',
                    }, scroll: { y: '100%' }, pagination: false, 
                    // pagination={{
                    //   size: 'small',
                    //   showTotal: (total, range) => (
                    //     <div className='pagination-content'>
                    //       <Select defaultValue={20} size='small' onChange={(value: any) => {
                    //         setMeta({
                    //           per_page: value,
                    //           current_page: 1,
                    //           total: meta?.total,
                    //         })
                    //         // onFilters({...filters, limit: value, offset: 0})
                    //       }}>
                    //         <Select.Option value={10}>10</Select.Option>
                    //         <Select.Option value={20}>20</Select.Option>
                    //         <Select.Option value={50}>50</Select.Option>
                    //       </Select>
                    //       <span>{`${range[0]}-${range[1]} de ${data.length} resultados`}</span>
                    //     </div>
                    //   ),
                    //   pageSize: meta?.per_page,
                    //   total: data.length,
                    //   // current: meta?.current_page,
                    //   showSizeChanger: false
                    // }}
                    rowKey: "_id", showSorterTooltip: false }) }), _jsx(ModalFeedback, { title: "se va a ".concat((user === null || user === void 0 ? void 0 : user.status) === 'ENABLED' ? 'deshabilitar' : 'habilitar', " el usuario:"), object: user === null || user === void 0 ? void 0 : user.fullName, status: user === null || user === void 0 ? void 0 : user.status, type: 'confirm', confirmTitle: "".concat((user === null || user === void 0 ? void 0 : user.status) === 'DISABLED' ? 'Habilitar' : 'Deshabilitar', " usuario"), visible: openModalConfirm, loadingConfirm: loadingUpdate, onOk: function () {
                    updateUser({ variables: { updateUserDto: { _id: user === null || user === void 0 ? void 0 : user._id, status: (user === null || user === void 0 ? void 0 : user.status) === 'ENABLED' ? 'DISABLED' : 'ENABLED' } } })
                        .then(function () {
                        setOpenModalConfirm(false);
                        setOpenModal(true);
                    });
                }, onCancel: function () {
                    setOpenModalConfirm(false);
                } }), _jsx(ModalFeedback, { title: "se ha ".concat((user === null || user === void 0 ? void 0 : user.status) === 'ENABLED' ? 'deshabilitado' : 'habilitado', " correctamente el usuario:"), object: user === null || user === void 0 ? void 0 : user.fullName, icon: 'disabled', visible: openModal, onOk: function () {
                    history.push('/users');
                    setOpenModal(false);
                } })] }));
};
export default PrintersTable;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
