var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { gql, useMutation } from "@apollo/client";
var SET_USER = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation SetUser($createUserDto: CreateUserDto!){\n  create(createUserDto: $createUserDto){\n      _id\n      fullName\n      userMail\n      position\n      role\n      createdAt\n      status\n    }\n  }\n"], ["\n  mutation SetUser($createUserDto: CreateUserDto!){\n  create(createUserDto: $createUserDto){\n      _id\n      fullName\n      userMail\n      position\n      role\n      createdAt\n      status\n    }\n  }\n"])));
export var useSetUser = function () {
    var _a = useMutation(SET_USER, { context: { clientName: 'authPath' }, update: function (cache, _a) {
            var create = _a.data.create;
            cache.modify({
                fields: {
                    findAll: function (existingUsers) {
                        if (existingUsers === void 0) { existingUsers = []; }
                        var newUserRef = cache.writeFragment({
                            data: create,
                            fragment: gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n                fragment NewUser on User {\n                  _id\n                  type\n                }\n              "], ["\n                fragment NewUser on User {\n                  _id\n                  type\n                }\n              "])))
                        });
                        return __spreadArray(__spreadArray([], existingUsers, true), [newUserRef], false);
                    }
                }
            });
        }
    }), setUser = _a[0], _b = _a[1], loading = _b.loading, error = _b.error, data = _b.data, called = _b.called;
    return [setUser, loading, error, data, called];
};
var templateObject_1, templateObject_2;
