var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ContainerTableFullH, TableFullH, TableActions, TableAction } from '../styles/Table';
import { Select as _Select } from 'antd';
import moment from 'moment';
import PencilIcon from '../icons/PencilIcon';
import ProhibitedIcon from '../icons/ProhibitedIcon';
import { ModalFeedback } from '../components/ModalFeedback';
import { useUpdateUser } from '../graphql/users/useUpdateUser';
import styled from 'styled-components';
import { useGetUsersCount } from '../graphql/users/useGetUsersCount';
import { useTranslation } from 'react-i18next';
var Select = styled(_Select)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 60px;\n  font-size: 12px;\n  font-weight: 500;\n  .ant-select-arrow{\n    color: ", ";\n  }\n"], ["\n  width: 60px;\n  font-size: 12px;\n  font-weight: 500;\n  .ant-select-arrow{\n    color: ", ";\n  }\n"])), function (props) { return props.theme.colors.primary; });
var StatusTag = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-radius: ", ";\n  font-size: 12px;\n  font-weight: 600;\n  padding: 4px 14px;\n  width: fit-content;\n\n  &.disabled{\n    background-color: ", ";\n    color: ", ";\n  }\n  &.enabled{\n    background-color: ", ";\n    color: ", ";\n  }\n"], ["\n  border-radius: ", ";\n  font-size: 12px;\n  font-weight: 600;\n  padding: 4px 14px;\n  width: fit-content;\n\n  &.disabled{\n    background-color: ", ";\n    color: ", ";\n  }\n  &.enabled{\n    background-color: ", ";\n    color: ", ";\n  }\n"])), function (props) { return props.theme.borderRadiusTwo; }, function (props) { return props.theme.helpers.borderColor; }, function (props) { return props.theme.grayScale.gray6; }, function (props) { return props.theme.colors.green; }, function (props) { return props.theme.colors.white; });
var UsersTable = function (_a) {
    var loading = _a.loading, data = _a.data, filters = _a.filters, onFilters = _a.onFilters;
    var history = useHistory();
    var _b = useState({ show: false }), error = _b[0], setError = _b[1];
    var _c = useState({
        per_page: filters.limit,
        current_page: (filters.offset / filters.limit) + 1,
        total: 0,
    }), meta = _c[0], setMeta = _c[1];
    var _d = useState(false), openModal = _d[0], setOpenModal = _d[1];
    var _e = useState(false), openModalConfirm = _e[0], setOpenModalConfirm = _e[1];
    var _f = useState(undefined), user = _f[0], setUser = _f[1];
    var t = useTranslation().t;
    var totalUsers = useGetUsersCount().data;
    var _g = useUpdateUser(), updateUser = _g[0], loadingUpdate = _g[1];
    useEffect(function () {
        if (totalUsers) {
            setMeta(__assign(__assign({}, meta), { total: totalUsers.UsersCount }));
        }
    }, [totalUsers]);
    var handleOnChange = function (pagination) {
        if ((filters.offset / filters.limit) + 1 !== pagination.current) {
            onFilters(function (f) { return (__assign(__assign({}, f), { offset: (pagination.current - 1) * filters.limit })); });
            setMeta(__assign(__assign({}, meta), { current_page: pagination.current }));
        }
    };
    var rowSelection = {
        onChange: function (selectedRowKeys, selectedRows) {
            console.log("selectedRowKeys: ".concat(selectedRowKeys), 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: function (record) { return ({
            disabled: record.name === 'Disabled User',
            name: record.name,
        }); },
    };
    var columns = [
        {
            title: 'Nombre',
            dataIndex: 'fullName',
            key: 'fullName',
            // width: '14%',
            render: function (value) { return value; }
        },
        {
            title: 'Email',
            dataIndex: 'userMail',
            key: 'userMail',
            // width: '28%'
        },
        {
            title: 'Cargo',
            dataIndex: 'position',
            key: 'position',
            // width: '14%',
            render: function (val) {
                return (_jsx("span", { children: val }));
            }
        },
        {
            title: 'Perfil',
            dataIndex: 'role',
            key: 'role',
            // width: '14%',
            render: function (val) {
                return val === 'OP' ? 'Operador' :
                    val === 'SU' ? 'Supervisor' :
                        val === 'MA' ? 'Gerente' :
                            val === 'MD' ? 'Encargado' :
                                val === 'SA' ? 'Super admin' : '-';
                //role: 'Manager' | 'Mandated' | 'Operator' | 'Supervisor',
            }
        },
        {
            title: 'Fecha de creación',
            dataIndex: 'createdAt',
            key: 'createdAt',
            // width: '14%',
            render: function (val) { return val === null ? '-' : moment(val, 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY'); }
        },
        {
            title: 'Estado',
            dataIndex: 'status',
            key: 'status',
            // width: '14%',
            render: function (value) {
                return (_jsx("span", { children: value === 'Enabled' ? _jsx(StatusTag, __assign({ className: 'enabled' }, { children: "Habilitado" })) : _jsx(StatusTag, __assign({ className: 'disabled' }, { children: "Deshabilitado" })) }));
            }
        },
        {
            title: 'Acción',
            dataIndex: 'actions',
            key: 'actions',
            // width: '10%',
            align: 'center',
            render: function (val, record) { return (_jsxs(TableActions, { children: [_jsx(TableAction, __assign({ onClick: function () { return history.push({ pathname: "/users/edit/userId=".concat(record._id) }); } }, { children: _jsx(PencilIcon, { colorOne: '#00D800', colorTwo: '#40FF00' }) })), _jsx(TableAction, __assign({ onClick: function () {
                            setUser({ _id: record._id, fullName: record.fullName, status: record.status });
                            setOpenModalConfirm(true);
                        } }, { children: _jsx(ProhibitedIcon, { color: '#00D800' }) }))] })); }
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(ContainerTableFullH, { children: _jsx(TableFullH, { columns: columns, dataSource: data, onChange: handleOnChange, loading: loading, 
                    /* rowSelection={{...rowSelection}} */
                    rowClassName: function (record, index) { return index % 2 === 0 ? 'table-row-light' : 'table-row-dark'; }, locale: {
                        emptyText: 'No hay usuarios',
                    }, scroll: { y: '100%' }, pagination: {
                        size: 'small',
                        showTotal: function (total, range) { return (_jsxs("div", __assign({ className: 'pagination-content' }, { children: [_jsxs(Select, __assign({ defaultValue: 20, size: 'small', onChange: function (value) {
                                        setMeta({
                                            per_page: value,
                                            current_page: 1,
                                            total: meta === null || meta === void 0 ? void 0 : meta.total,
                                        });
                                        onFilters(__assign(__assign({}, filters), { limit: value, offset: 0 }));
                                    } }, { children: [_jsx(Select.Option, __assign({ value: 10 }, { children: "10" })), _jsx(Select.Option, __assign({ value: 20 }, { children: "20" })), _jsx(Select.Option, __assign({ value: 50 }, { children: "50" }))] })), _jsx("span", { children: "".concat(range[0], "-").concat(range[1], " ").concat(t('general.labels.of'), " ").concat(meta === null || meta === void 0 ? void 0 : meta.total, " ").concat(t('general.labels.results')) })] }))); },
                        pageSize: meta === null || meta === void 0 ? void 0 : meta.per_page,
                        total: meta === null || meta === void 0 ? void 0 : meta.total,
                        current: meta === null || meta === void 0 ? void 0 : meta.current_page,
                        showSizeChanger: false
                    }, rowKey: "_id", showSorterTooltip: false }) }), _jsx(ModalFeedback, { title: "se va a ".concat((user === null || user === void 0 ? void 0 : user.status) === 'Enabled' ? 'deshabilitar' : 'habilitar', " el usuario:"), object: user === null || user === void 0 ? void 0 : user.fullName, status: user === null || user === void 0 ? void 0 : user.status, type: 'confirm', confirmTitle: "".concat((user === null || user === void 0 ? void 0 : user.status) === 'Disabled' ? 'Habilitar' : 'Deshabilitar', " usuario"), visible: openModalConfirm, loadingConfirm: loadingUpdate, onOk: function () {
                    updateUser({ variables: { updateUserDto: { _id: user === null || user === void 0 ? void 0 : user._id, status: (user === null || user === void 0 ? void 0 : user.status) === 'Enabled' ? 'Disabled' : 'Enabled' } } })
                        .then(function () {
                        setOpenModalConfirm(false);
                        setOpenModal(true);
                    })
                        .catch(function (error) {
                        console.log({ user: user });
                        setError({ show: true, message: error.graphQLErrors[0].message });
                    });
                }, onCancel: function () {
                    setOpenModalConfirm(false);
                } }), _jsx(ModalFeedback, { title: "se ha ".concat((user === null || user === void 0 ? void 0 : user.status) === 'Enabled' ? 'deshabilitado' : 'habilitado', " correctamente el usuario:"), object: user === null || user === void 0 ? void 0 : user.fullName, icon: 'disabled', visible: openModal, onOk: function () {
                    history.push('/users');
                    setOpenModal(false);
                } }), _jsx(ModalFeedback, { title: error.message, visible: error.show, type: 'error', icon: '', simple: true, okBtnText: "".concat(t('general.buttons.close')), onOk: function () { return setError({ show: false, message: '' }); } })] }));
};
export default UsersTable;
var templateObject_1, templateObject_2;
