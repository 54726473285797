var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var PrintFileIcon = function (_a) {
    var colorOne = _a.colorOne, colorTwo = _a.colorTwo;
    return (_jsx("svg", __assign({ xmlns: 'http://www.w3.org/2000/svg', width: '0.81em', height: '1em', viewBox: '0 0 18.933 23.425' }, { children: _jsxs("g", __assign({ id: 'Grupo_634', "data-name": 'Grupo 634', transform: 'translate(-442.215 -505.229)' }, { children: [_jsx("path", { id: 'Trazado_122', "data-name": 'Trazado 122', d: 'M453.657,26.431h3.974a1.945,1.945,0,0,0-.3-.37l-3.925-3.925a2.162,2.162,0,0,0-.37-.3v3.974a.611.611,0,0,0,.617.617Z', transform: 'translate(3.171 483.735)', fill: colorTwo ? colorTwo : '#40ff00' }), _jsx("path", { id: 'Trazado_123', "data-name": 'Trazado 123', d: 'M150.133,20.591a2.265,2.265,0,0,1-2.271-2.271V14H137.717a2.2,2.2,0,0,0-2.2,2.2V35.228a2.2,2.2,0,0,0,2.2,2.2h14.539a2.2,2.2,0,0,0,2.2-2.2V20.591Zm-3.357,11.651-2.3,1.432a.374.374,0,0,1-.568-.321v-.913c-.074-.025-.123-.025-.2-.049a4.022,4.022,0,0,1-2.814-3.826,4.142,4.142,0,0,1,.543-2.024.686.686,0,0,1,1.185.691,2.727,2.727,0,0,0-.346,1.308,2.65,2.65,0,0,0,1.6,2.394v-.494a.374.374,0,0,1,.568-.321l2.3,1.432c.272.222.272.543.025.691Zm1.728-1.753a.694.694,0,0,1-.592.346.566.566,0,0,1-.346-.1.689.689,0,0,1-.247-.938,2.727,2.727,0,0,0,.346-1.308,2.677,2.677,0,0,0-1.6-2.394v.494a.374.374,0,0,1-.568.321l-2.3-1.432a.369.369,0,0,1,0-.617l2.3-1.432a.374.374,0,0,1,.568.321v.913c.074.025.123.025.2.049a4.022,4.022,0,0,1,2.814,3.826,4.327,4.327,0,0,1-.568,1.95Z', transform: 'translate(306.695 491.229)', fill: colorOne ? colorOne : '#00D800' })] })) })));
};
export default PrintFileIcon;
