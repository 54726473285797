var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useContext } from 'react';
import { AppCtx } from '../../context/AppContext';
import { useHistory } from 'react-router-dom';
import { ContainerTableFullH, TableFullH } from '../../styles/Table';
import { Select as _Select } from 'antd';
import { ModalFeedback } from '../../components/ModalFeedback';
import styled from 'styled-components';
import { Wrapper, ScrollContainer } from '../../styles/Section';
import AppTopBar from '../../app/AppTopBar';
import PrintGrListTools from './PrintGrListTools';
import { ModalTable } from '../../components/ModalTable';
import PrintGrListTable from './PrintGrListTable';
import { usePrintsCreate } from '../../graphql/print/usePrintsCreate';
import CheckIcon from '../../icons/CheckIcon';
import { useTranslation } from 'react-i18next';
var Select = styled(_Select)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 60px;\n  font-size: 12px;\n  font-weight: 500;\n  .ant-select-arrow{\n    color: ", ";\n  }\n"], ["\n  width: 60px;\n  font-size: 12px;\n  font-weight: 500;\n  .ant-select-arrow{\n    color: ", ";\n  }\n"])), function (props) { return props.theme.colors.primary; });
var PrintGrList = function () {
    var ctx = useContext(AppCtx);
    var t = useTranslation().t;
    var _a = useState({ show: false }), error = _a[0], setError = _a[1];
    var _b = useState(undefined), currentModal = _b[0], setCurrentModal = _b[1];
    var _c = useState({
        per_page: 20,
    }), meta = _c[0], setMeta = _c[1];
    var _d = usePrintsCreate(), setPrintsCreate = _d[0], loading = _d[1];
    var history = useHistory();
    useEffect(function () {
        if ((ctx === null || ctx === void 0 ? void 0 : ctx.prints.length) === 0) {
            history.push('/print/gr');
        }
    }, []);
    var handleOnChange = function (pagination) { };
    var rowSelection = {
        onChange: function (selectedRowKeys, selectedRows) {
            console.log("selectedRowKeys: ".concat(selectedRowKeys), 'selectedRows: ', selectedRows);
            ctx === null || ctx === void 0 ? void 0 : ctx.setTagsToPrint(selectedRows);
            var x = __spreadArray([], selectedRows.map(function (t) { return t.quantity; }), true).reduce(function (_t, total) { return _t + total; }, 0);
            ctx === null || ctx === void 0 ? void 0 : ctx.setTotalToPrint(x);
        },
        getCheckboxProps: function (record) { return ({
            disabled: record.exists === false,
            name: record.exists,
        }); },
    };
    var handleFinishPrint = function () {
        var _a;
        setCurrentModal(undefined);
        var allPrints = ctx === null || ctx === void 0 ? void 0 : ctx.prints.map(function (p) { return p; });
        var printed = (_a = ctx === null || ctx === void 0 ? void 0 : ctx.tagsToPrint) === null || _a === void 0 ? void 0 : _a.map(function (t) { return (__assign(__assign({}, t), { status: true })); });
        var final = allPrints === null || allPrints === void 0 ? void 0 : allPrints.map(function (p) { return (printed === null || printed === void 0 ? void 0 : printed.find(function (_p) { return _p._id === p._id; })) || p; });
        ctx === null || ctx === void 0 ? void 0 : ctx.setPrints(final ? final : []);
    };
    var handleCreatePrints = function (prints) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, setPrintsCreate({ variables: { createPrintDtos: prints } })];
                case 1:
                    response = _a.sent();
                    setCurrentModal(3);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.log(error_1);
                    setError({ show: true, message: error_1.graphQLErrors[0].message });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var columns = [
        {
            title: 'Stock code',
            dataIndex: 'stockCode',
            key: 'stockCode',
            // width: '14%',
            render: function (value) { return value; }
        },
        {
            title: 'Descripción',
            dataIndex: 'description',
            key: 'description',
            render: function (val, record) {
                return (_jsx(_Fragment, { children: record.exists ? val : 'No Existe en Maestro de Materiales' }));
            }
            // width: '28%'
        },
        {
            title: 'Cantidad en OC',
            dataIndex: 'quantityOnPurchaseOrder',
            key: 'quantityOnPurchaseOrder',
            render: function (val) { return val ? val : '-'; }
            // width: '28%'
        },
        {
            title: 'Cantidad a imprimir',
            dataIndex: 'quantityOnPurchaseOrder',
            key: 'quantityOnPurchaseOrder',
            render: function (val) { return val ? val : '-'; }
            // width: '28%'
        },
        {
            title: 'Unidad de medida',
            dataIndex: 'unitOfMeasurement',
            key: 'unitOfMeasurement',
            render: function (val) { return val ? val : '-'; }
            // width: '28%'
        },
        {
            title: 'Storage Bin',
            dataIndex: 'storageBin',
            key: 'storageBin',
            // width: '14%',
            render: function (val) { return val ? val : '-'; }
        },
        {
            title: 'Orden de compra',
            dataIndex: 'purchaseOrder',
            key: 'purchaseOrder',
            // width: '14%',
            render: function (val) { return val ? val : '-'; }
        },
        {
            title: 'Estado',
            dataIndex: 'status',
            key: 'status',
            render: function (val) {
                return (_jsx("span", { children: val ? _jsx(CheckIcon, { color: '#00D800' }) : '-' }));
            }
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsxs(Wrapper, { children: [_jsx(AppTopBar, __assign({ sectionName: "".concat(t('main_menu.print'), " / ").concat(t('main_menu.print_sub_menu.rgr_print')), onBack: function () { return history.push('/print/gr'); } }, { children: _jsxs("div", __assign({ style: { marginLeft: 5, fontSize: 16, color: '#000000', fontWeight: 600 } }, { children: ["/ ", t('general.labels.material_list')] })) })), _jsxs(ScrollContainer, { children: [_jsx(PrintGrListTools, { type: ctx === null || ctx === void 0 ? void 0 : ctx.printType, printer: ctx === null || ctx === void 0 ? void 0 : ctx.selectedPrinter, onPrint: setCurrentModal }), _jsx(ContainerTableFullH, { children: _jsx(TableFullH, { type: 'selectable', columns: columns, dataSource: ctx === null || ctx === void 0 ? void 0 : ctx.prints, onChange: handleOnChange, loading: false, rowSelection: __assign({}, rowSelection), locale: {
                                        emptyText: 'No hay datos',
                                    }, scroll: { y: '100%' }, pagination: {
                                        size: 'small',
                                        showTotal: function (total, range) { return (_jsxs("div", __assign({ className: 'pagination-content' }, { children: [_jsxs(Select, __assign({ defaultValue: 20, size: 'small', onChange: function (value) { return setMeta({
                                                        per_page: value,
                                                    }); } }, { children: [_jsx(Select.Option, __assign({ value: 10 }, { children: "10" })), _jsx(Select.Option, __assign({ value: 20 }, { children: "20" })), _jsx(Select.Option, __assign({ value: 50 }, { children: "50" }))] })), _jsx("span", { children: "".concat(range[0], "-").concat(range[1], " ").concat(t('general.labels.of'), " ").concat(ctx === null || ctx === void 0 ? void 0 : ctx.prints.length, " ").concat(t('general.labels.results')) })] }))); },
                                        pageSize: meta === null || meta === void 0 ? void 0 : meta.per_page,
                                        total: ctx === null || ctx === void 0 ? void 0 : ctx.prints.length,
                                        showSizeChanger: false
                                    }, rowKey: "_id", showSorterTooltip: false }) })] })] }), _jsx(ModalTable, { title: "".concat(t('sections.print_section.update_data_quantity')), total: ctx === null || ctx === void 0 ? void 0 : ctx.totalToPrint, visible: currentModal === 1, simpleTextbtn: "".concat(t('general.buttons.send')), onOk: function () { return setCurrentModal(2); }, onCancel: function () { return setCurrentModal(undefined); }, table: _jsx(PrintGrListTable, { data: ctx === null || ctx === void 0 ? void 0 : ctx.tagsToPrint, onSetTags: ctx === null || ctx === void 0 ? void 0 : ctx.setTagsToPrint }) }), _jsx(ModalFeedback, { title: "".concat(t('sections.print_section.confirm_labels_message')), object: _jsx("span", __assign({ style: {
                        color: '#00D800',
                        fontSize: '18px',
                        fontWeight: '700'
                    } }, { children: ctx === null || ctx === void 0 ? void 0 : ctx.totalToPrint })), type: 'confirm', confirmTitle: "".concat(t('sections.print_section.confirm_labels')), visible: currentModal === 2, loadingConfirm: loading, onOk: function () {
                    var arr = (ctx === null || ctx === void 0 ? void 0 : ctx.tagsToPrint) ? ctx === null || ctx === void 0 ? void 0 : ctx.tagsToPrint.map(function (t) { return t; }) : [];
                    var prints = arr.map(function (t) {
                        var _a;
                        return ({
                            origin: 'rgr',
                            storageLocation: t.storageLocation,
                            description: t.description,
                            printerId: (_a = ctx === null || ctx === void 0 ? void 0 : ctx.selectedPrinter) === null || _a === void 0 ? void 0 : _a._id,
                            purchaseOrder: t.purchaseOrder,
                            quantity: t.quantity,
                            stockCode: t.stockCode,
                            storageBin: t.storageBin,
                            valuationType: ""
                        });
                    });
                    handleCreatePrints(prints);
                }, onCancel: function () { return setCurrentModal(1); } }), _jsx(ModalFeedback, { title: _jsxs("div", __assign({ style: { marginTop: '20px', maxWidth: '444px', } }, { children: ["".concat(t('sections.print_section.success_feedback')), " ", _jsx("span", __assign({ style: { color: '#000', fontWeight: '700' } }, { children: "\"".concat(t('general.labels.print_history'), "\"") }))] })), icon: 'check', visible: currentModal === 3, onOk: handleFinishPrint }), _jsx(ModalFeedback, { title: error.message, visible: error.show, type: 'error', icon: '', simple: true, okBtnText: "".concat(t('general.buttons.close')), onOk: function () { return setError({ show: false, message: '' }); } })] }));
};
export default PrintGrList;
var templateObject_1;
