var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var SupportIcon = function (_a) {
    var color = _a.color, width = _a.width;
    return (_jsx("svg", __assign({ xmlns: 'http://www.w3.org/2000/svg', width: width ? width : '1em', height: width ? (width * 0.99) : '0.99em', viewBox: '0 0 23.661 23.425' }, { children: _jsx("path", { id: 'noun-help-3845841', d: 'M188.194,65.692a11.852,11.852,0,0,0-23.661,0,10.614,10.614,0,0,0,4.526,8.555,4.2,4.2,0,0,1-2.164,1.568,1.055,1.055,0,0,0-.655.972,1.02,1.02,0,0,0,.734.933,16.716,16.716,0,0,0,3.752.476,7.232,7.232,0,0,0,5.042-1.648c.2.02.4.02.6.02h0a11.394,11.394,0,0,0,11.831-10.878Zm-10.858,4.724a.993.993,0,0,1-.992.992,1.008,1.008,0,0,1-.992-.992,1.048,1.048,0,0,1,.992-1.052.947.947,0,0,1,.992.933Zm.536-3.97a2.687,2.687,0,0,0-.536.576.992.992,0,0,1-.992.992A1.012,1.012,0,0,1,175.351,67a2.778,2.778,0,0,1,1.171-2c.576-.556.933-.933.933-1.628a1.054,1.054,0,0,0-1.112-1.132,1.1,1.1,0,0,0-1.072,1.112.992.992,0,1,1-1.985,0,3.078,3.078,0,0,1,3.057-3.1,3,3,0,0,1,3.1,3.116,4.1,4.1,0,0,1-1.568,3.077Z', transform: 'translate(-164.533 -54.786)', fill: color ? color : '#00D800' }) })));
};
export default SupportIcon;
