import { useState, useEffect } from 'react';
export default function useDebounce(value, delay) {
    var _a = useState(value), debounce = _a[0], setDebounce = _a[1];
    useEffect(function () {
        var handleDebounce = setTimeout(function () { return setDebounce(value); }, delay);
        return function () {
            clearTimeout(handleDebounce);
        };
    }, [value]);
    return debounce;
}
