var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var UserIcon = function (_a) {
    var colorOne = _a.colorOne, colorTwo = _a.colorTwo;
    return (_jsxs("svg", __assign({ id: 'Grupo_630', "data-name": 'Grupo 630', xmlns: 'http://www.w3.org/2000/svg', width: '0.96em', height: '1em', viewBox: '0 0 22.443 23.163' }, { children: [_jsx("g", __assign({ id: 'noun-user-4875762', transform: 'translate(0 12.837)' }, { children: _jsx("path", { id: 'Trazado_78', "data-name": 'Trazado 78', d: 'M153.281,196.185a8.3,8.3,0,0,0-8.276-6.8h-5.737a8.3,8.3,0,0,0-8.276,6.8,2.869,2.869,0,0,0,2.795,3.522h16.7a2.868,2.868,0,0,0,2.794-3.522Z', transform: 'translate(-130.915 -189.381)', fill: colorOne ? colorOne : '#b5b5c2' }) })), _jsx("g", __assign({ id: 'noun-user-4875762-2', "data-name": 'noun-user-4875762', transform: 'translate(5.485 0)' }, { children: _jsx("path", { id: 'Trazado_78-2', "data-name": 'Trazado 78', d: 'M188.882,61.737a5.737,5.737,0,1,1,5.737,5.737A5.743,5.743,0,0,1,188.882,61.737Z', transform: 'translate(-188.882 -56)', fill: colorTwo ? colorTwo : '#e9e9ed' }) }))] })));
};
export default UserIcon;
