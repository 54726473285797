import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Switch, Route } from 'react-router-dom';
import Users from './Users';
import UsersCreate from './UsersCreate';
var UsersRouter = function (_a) {
    var user = _a.user;
    return (_jsx(Switch, { children: ((user === null || user === void 0 ? void 0 : user.role) === 'SA' || (user === null || user === void 0 ? void 0 : user.role) === 'SU' || (user === null || user === void 0 ? void 0 : user.role) === 'SD') &&
            _jsxs(_Fragment, { children: [_jsx(Route, { path: '/users/create', children: _jsx(UsersCreate, {}) }), _jsx(Route, { path: '/users/edit/userId=:userId', children: _jsx(UsersCreate, {}) }), _jsx(Route, { path: '/users', children: _jsx(Users, {}) })] }) }));
};
export default UsersRouter;
