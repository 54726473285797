var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var LeftArrowIcon = function (_a) {
    var color = _a.color;
    return (_jsx("svg", __assign({ xmlns: 'http://www.w3.org/2000/svg', width: '1em', height: '1em', viewBox: '0 0 23.425 23.425' }, { children: _jsx("g", __assign({ id: 'noun-back-1921113', transform: 'translate(-84 -14)' }, { children: _jsx("path", { id: 'Trazado_161', "data-name": 'Trazado 161', d: 'M107.425,25.713A11.713,11.713,0,1,0,95.713,37.425,11.723,11.723,0,0,0,107.425,25.713ZM95.762,30.324l-3.477-3.5a1.613,1.613,0,0,1-.469-1.11,1.511,1.511,0,0,1,.469-1.11l3.5-3.5a1.569,1.569,0,0,1,2.219,2.219l-2.392,2.392L98.006,28.1a1.563,1.563,0,0,1,0,2.219,1.593,1.593,0,0,1-2.244,0Z', fill: color ? color : '#b5b5c2' }) })) })));
};
export default LeftArrowIcon;
