var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql, useLazyQuery } from "@apollo/client";
var GET_HISTORY = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query History($historyQueryDto: HistoryQueryDto!){\n    history(historyQueryDto:$historyQueryDto){\n      author{\n        _id\n        fullName\n        userMail\n        username\n      }\n      createdAt\n      description\n      origin\n      printerId\n      purchaseOrder\n      quantity\n      status\n      stockCode\n      storageBin\n      valuationType\n    }\n  }\n"], ["\n  query History($historyQueryDto: HistoryQueryDto!){\n    history(historyQueryDto:$historyQueryDto){\n      author{\n        _id\n        fullName\n        userMail\n        username\n      }\n      createdAt\n      description\n      origin\n      printerId\n      purchaseOrder\n      quantity\n      status\n      stockCode\n      storageBin\n      valuationType\n    }\n  }\n"])));
export var useGetHistory = function () {
    var _a = useLazyQuery(GET_HISTORY, { context: { clientName: 'printPath' } }), getHistory = _a[0], _b = _a[1], loading = _b.loading, error = _b.error, data = _b.data, called = _b.called;
    return [
        getHistory,
        loading,
        error,
        data,
        called,
    ];
};
var templateObject_1;
