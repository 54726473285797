var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql, useMutation } from "@apollo/client";
var UPDATE_PROFILE = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation UpdateProfile($updateProfileDto: UpdateProfileDto!){\n    updateProfile(updateProfileDto: $updateProfileDto){\n      _id\n      companyCode\n      createdAt\n      fullName\n      isNewUser\n      plantCode\n      role\n      status\n      userMail\n      username\n    }\n  }\n"], ["\n  mutation UpdateProfile($updateProfileDto: UpdateProfileDto!){\n    updateProfile(updateProfileDto: $updateProfileDto){\n      _id\n      companyCode\n      createdAt\n      fullName\n      isNewUser\n      plantCode\n      role\n      status\n      userMail\n      username\n    }\n  }\n"])));
export var useUpdateProfile = function () {
    var _a = useMutation(UPDATE_PROFILE, { context: { clientName: 'authPath' },
    }), updateProfile = _a[0], _b = _a[1], loading = _b.loading, error = _b.error, data = _b.data, called = _b.called;
    return [updateProfile, loading, error, data, called];
};
var templateObject_1;
