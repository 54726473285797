var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Form, Input as AntdInput, Select as AntdSelect } from 'antd';
import { Button } from '../styles/Button';
import { linearGradient } from 'polished';
import loginImg from '../../assets/images/login/background.jpg';
import logoIconImg from '../../assets/images/login/logoIcon.svg';
import logo from '../../assets/images/logo.svg';
import styled from 'styled-components';
import { AppCtx } from '../context/AppContext';
import { useMutation, gql } from '@apollo/client';
import { ModalFeedback } from '../components/ModalFeedback';
var AuthWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100vh;\n  display: flex;\n"], ["\n  height: 100vh;\n  display: flex;\n"])));
var AuthLeftContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 50%;\n  height: 100%;\n  background-image: url(", ");\n  background-position: right -12px;\n  background-repeat: no-repeat;\n  background-size: cover;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n"], ["\n  width: 50%;\n  height: 100%;\n  background-image: url(", ");\n  background-position: right -12px;\n  background-repeat: no-repeat;\n  background-size: cover;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n"])), loginImg);
var AuthLeftContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: absolute;\n  bottom: 0; \n  height: 60vh;\n  display: flex ;\n  justify-content:flex-end ;\n  align-items: center;\n  flex-direction: column;\n  ", ";\n  width: 100%;\n"], ["\n  position: absolute;\n  bottom: 0; \n  height: 60vh;\n  display: flex ;\n  justify-content:flex-end ;\n  align-items: center;\n  flex-direction: column;\n  ", ";\n  width: 100%;\n"])), linearGradient({
    colorStops: ['transparent 0%', '#000 100%', '#000 100%'],
    fallback: 'transparent',
}));
var AuthLeftDots = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-bottom: 24px ;\n"], ["\n  margin-bottom: 24px ;\n"])));
var AuthLeftText = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: white;\n  font-weight: 600;\n\n  span{\n    color: ", ";\n  }\n"], ["\n  color: white;\n  font-weight: 600;\n\n  span{\n    color: ", ";\n  }\n"])), function (props) { return props.theme.colors.primary; });
var AuthRightContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  height: 100vh;\n  justify-content: center;\n  padding: 0 140px 0 80px;\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  height: 100vh;\n  justify-content: center;\n  padding: 0 140px 0 80px;\n"])));
var AuthRightContentHeader = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  border-bottom: 1px solid ", ";\n  padding: 12px 0;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  border-bottom: 1px solid ", ";\n  padding: 12px 0;\n"])), function (props) { return props.theme.helpers.borderColor; });
var AuthLanguage = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-left: auto;\n  display: flex;\n  align-items: center;\n"], ["\n  margin-left: auto;\n  display: flex;\n  align-items: center;\n"])));
var AuthRightContentBody = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  /* padding: 16px; */\n  justify-content: flex-start;\n  align-items: center;\n"], ["\n  display: flex;\n  flex: 1;\n  /* padding: 16px; */\n  justify-content: flex-start;\n  align-items: center;\n"])));
var AuthRightContentFooter = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  padding: 16px;\n"], ["\n  padding: 16px;\n"])));
var AuthFooterLinks = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  border-top: 1px solid ", ";\n  cursor: pointer;\n"], ["\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  border-top: 1px solid ", ";\n  cursor: pointer;\n"])), function (props) { return props.theme.helpers.borderColor; });
var AuthFooterLink = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  margin-top: 16px;;\n\n"], ["\n  margin-top: 16px;;\n\n"])));
var AuthForm = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  max-width: 360px;\n  min-width: 360px;\n  /* margin: auto; */\n"], ["\n  max-width: 360px;\n  min-width: 360px;\n  /* margin: auto; */\n"])));
var AuthFormLogo = styled.img(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  width: 200px;\n"], ["\n  width: 200px;\n"])));
var AuthFormTitle = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  font-weight: 600;\n  font-size: 18px;\n  text-align: left;\n  color: ", ";\n  margin-top: 18px;\n\n  span{\n    color: ", ";\n  }\n"], ["\n  font-weight: 600;\n  font-size: 18px;\n  text-align: left;\n  color: ", ";\n  margin-top: 18px;\n\n  span{\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.grayScale.gray5;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
});
var AuthFormText = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  font-size: 14px;\n  color: ", ";\n  margin-top: 20px;\n"], ["\n  font-size: 14px;\n  color: ", ";\n  margin-top: 20px;\n"])), function (props) { return props.theme.grayScale.gray5; });
var AuthFormCotent = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  margin: 40px 0;\n"], ["\n  margin: 40px 0;\n"])));
var AuthForgot = styled.div(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  text-align: left;\n  font-size: ", ";\n  a{\n    color: ", ";\n    text-decoration: underline;\n    font-weight: 600;\n  }\n"], ["\n  text-align: left;\n  font-size: ", ";\n  a{\n    color: ", ";\n    text-decoration: underline;\n    font-weight: 600;\n  }\n"])), function (props) { return props.theme.fontSizes.small; }, function (_a) {
    var theme = _a.theme;
    return theme.grayScale.gray5;
});
var FormItem = styled(Form.Item)(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  .ant-form-item-explain{\n    font-size: 12px;\n    color: ", ";\n  }\n  .ant-form-item-label{\n    label{\n      font-size: 12px;\n      font-weight: 600;\n    }\n  }\n"], ["\n  .ant-form-item-explain{\n    font-size: 12px;\n    color: ", ";\n  }\n  .ant-form-item-label{\n    label{\n      font-size: 12px;\n      font-weight: 600;\n    }\n  }\n"])), function (props) { return props.theme.colors.error; });
var Input = styled(AntdInput)(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  &.ant-input-affix-wrapper-lg {\n      padding: 12.9px 16px;\n      font-size: 18px;\n      .ant-input-prefix{\n        margin-right: 8px;\n      }\n  }\n  &:-internal-autofill-selected {\n    background-color: initial !important;\n  }\n"], ["\n  &.ant-input-affix-wrapper-lg {\n      padding: 12.9px 16px;\n      font-size: 18px;\n      .ant-input-prefix{\n        margin-right: 8px;\n      }\n  }\n  &:-internal-autofill-selected {\n    background-color: initial !important;\n  }\n"])));
var InputPassword = styled(Input.Password)(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n"], ["\n"])));
var Select = styled(AntdSelect)(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n  font-weight: 600;\n  font-size: 12px;\n"], ["\n  font-weight: 600;\n  font-size: 12px;\n"])));
var UPDATE_PASSWORD = gql(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n  mutation UpdatePassword($updatePasswordDto: UpdatePasswordDto!){\n    updatePassword(updatePasswordDto: $updatePasswordDto)\n  }\n"], ["\n  mutation UpdatePassword($updatePasswordDto: UpdatePasswordDto!){\n    updatePassword(updatePasswordDto: $updatePasswordDto)\n  }\n"])));
export default function AuthResetPassword() {
    var _this = this;
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState({ show: false }), showFeedback = _b[0], setShowFeedback = _b[1];
    var form = Form.useForm()[0];
    var ctx = useContext(AppCtx);
    var token = useParams().token;
    var history = useHistory();
    var t = useTranslation().t;
    var updatePassword = useMutation(UPDATE_PASSWORD, { context: { clientName: 'authPath' } })[0];
    var handleCompare = function (rule, value) {
        if (value && form.getFieldValue('rePassword') !== form.getFieldValue('password')) {
            return Promise.reject('Las contraseñas deben ser iguales');
        }
        else {
            return Promise.resolve();
        }
    };
    var onFinish = function (values) { return __awaiter(_this, void 0, void 0, function () {
        var password, rePassword, response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    password = values.password, rePassword = values.rePassword;
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    setLoading(true);
                    return [4 /*yield*/, updatePassword({
                            variables: {
                                updatePasswordDto: {
                                    password: password,
                                    token: token
                                }
                            }
                        })];
                case 2:
                    response = _a.sent();
                    setLoading(false);
                    setShowFeedback({ show: true, type: 'finish' });
                    console.log({ response: response });
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    setLoading(false);
                    console.log('error', error_1);
                    setShowFeedback({ show: true, message: error_1.graphQLErrors[0].message, type: 'error' });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var onFinishFailed = function (errorInfo) {
        console.log('Failed:', errorInfo);
    };
    return (_jsxs(AuthWrapper, { children: [_jsx(AuthLeftContainer, { children: _jsxs(AuthLeftContent, { children: [_jsx(AuthLeftDots, { children: _jsx("img", { src: logoIconImg, width: 76 }) }), _jsxs(AuthLeftText, { children: ["SmartBot ", _jsx("span", { children: "2.0" }), " - RAFCOM"] }), _jsx(AuthLeftText, __assign({ style: { fontSize: '14px', fontWeight: 500 } }, { children: "\u00A9Todos los derechos reservados" })), _jsx(AuthLeftText, __assign({ style: { marginBottom: 48, marginTop: 24, fontSize: '14px', fontWeight: 500 } }, { children: "contacto@rafcom.cl" }))] }) }), _jsxs(AuthRightContainer, { children: [_jsx(AuthRightContentHeader, { children: _jsx(AuthLanguage, { children: _jsxs(Select, __assign({ defaultValue: ctx === null || ctx === void 0 ? void 0 : ctx.lng, size: "small", placeholder: "seleccione", style: { width: '112px' }, onChange: function (value) { return ctx === null || ctx === void 0 ? void 0 : ctx.setLng(value); } }, { children: [_jsx(Select.Option, __assign({ value: 'es' }, { children: "Espa\u00F1ol" })), _jsx(Select.Option, __assign({ value: 'en' }, { children: "English" }))] })) }) }), _jsx(AuthRightContentBody, { children: _jsxs(AuthForm, { children: [_jsx(AuthFormLogo, { src: logo }), _jsx(AuthFormTitle, { children: "Crear nueva constrase\u00F1a" }), _jsx(AuthFormCotent, { children: _jsxs(Form, __assign({ onFinish: onFinish, onFinishFailed: onFinishFailed, layout: 'vertical', requiredMark: false, form: form }, { children: [_jsx(FormItem, __assign({ name: "password", label: t('general.labels.new_password'), dependencies: ['rePassword'], rules: [
                                                    { required: true, message: 'Por favor ingrese una contraseña' },
                                                    { validator: function (rule, value) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                                            return [2 /*return*/, handleCompare(rule, value)];
                                                        }); }); } }
                                                ] }, { children: _jsx(InputPassword, { size: "large", autoComplete: 'off' }) })), _jsx(FormItem, __assign({ name: "rePassword", label: t('general.labels.password_repeat'), dependencies: ['password'], rules: [
                                                    { required: true, message: 'Por favor repita la nueva contraseña', },
                                                    { validator: function (rule, value) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                                                            return [2 /*return*/, handleCompare(rule, value)];
                                                        }); }); } }
                                                ] }, { children: _jsx(InputPassword, { size: "large", autoComplete: 'off' }) })), _jsx(FormItem, __assign({ style: { paddingTop: 24 } }, { children: _jsx(Button, __assign({ block: true, size: "large", type: "primary", htmlType: "submit", loading: loading }, { children: "Crear nueva contrase\u00F1a" })) }))] })) }), _jsx(AuthForgot, { children: _jsx(Link, __assign({ to: "/auth" }, { children: "Salir" })) })] }) }), _jsx(AuthRightContentFooter, {})] }), _jsx(ModalFeedback, { title: showFeedback.type !== 'error' ? _jsxs("div", __assign({ style: { marginTop: '20px' } }, { children: [_jsx("span", __assign({ style: { color: '#000', fontWeight: '700' } }, { children: "Contrase\u00F1a modificada con \u00E9xito" })), " ", _jsx("br", {}), " Para ingresar vuelva al inicio ", _jsx("br", {}), " y acceda con sus nuevas credenciales"] })) : showFeedback.message, visible: showFeedback.show, type: showFeedback.type, icon: showFeedback.type !== 'error' ? 'check' : '', simple: true, okBtnText: showFeedback.type === 'error' ? 'cerrar' :
                    showFeedback.type === 'finish' ? 'Login' : 'Aceptar', onOk: function () {
                    setShowFeedback({ show: false, message: '', type: '' });
                    showFeedback.type === 'finish' && history.push('/auth');
                } })] }));
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23;
