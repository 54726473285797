var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var PrinterIcon = function (_a) {
    var color = _a.color;
    return (_jsx("svg", __assign({ xmlns: 'http://www.w3.org/2000/svg', width: '1em', height: '1em', viewBox: '0 0 23.757 23.425' }, { children: _jsxs("g", __assign({ id: 'noun-print-976382', transform: 'translate(0 0)' }, { children: [_jsx("path", { id: 'Trazado_74', "data-name": 'Trazado 74', d: 'M194.559,181.426v9.651a2.227,2.227,0,0,1-2.227,2.227h-1.485v2.227a2.227,2.227,0,0,1-2.227,2.227H176.742a2.227,2.227,0,0,1-2.227-2.227V193.3H173.03a2.227,2.227,0,0,1-2.227-2.227v-9.651a2.227,2.227,0,0,1,2.227-2.227h1.485v5.2h16.333v-5.2h1.485a2.227,2.227,0,0,1,2.227,2.227Zm-6.682,8.166H177.484v5.2h10.394Z', transform: 'translate(-170.803 -174.334)', fill: color ? color : '#b5b5c2' }), _jsx("g", __assign({ id: 'noun-upload-1664847', transform: 'translate(-231.167 -142.913)' }, { children: _jsx("path", { id: 'Trazado_177', "data-name": 'Trazado 177', d: 'M246.826,146.717l-3.629-3.746a.227.227,0,0,0-.3,0l-3.629,3.746c-.166.154-.118.28.107.28H241v3.879a.2.2,0,0,0,.2.2h3.675a.2.2,0,0,0,.2-.2V147h1.632c.225,0,.272-.126.107-.28Z', transform: 'translate(0 0)', fill: color ? color : '#b5b5c2' }) }))] })) })));
};
export default PrinterIcon;
