var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AppTopBar from '../../app/AppTopBar';
import { Wrapper, ScrollContainer } from '../../styles/Section';
import DashboardPrintsTable from './DashboardPrintsTable';
import DashboardPrintsTableTools from './DashboardPrintsTableTools';
import DashboardCharts from './DashboardCharts';
import { useGetHistoryPrints } from '../../graphql/dashboard/useGetHistoryPrints';
import { ModalFeedback } from '../../components/ModalFeedback';
import { useTranslation } from 'react-i18next';
var DashboardPrints = function () {
    var _a;
    var endDate = new Date();
    var startDate = new Date(new Date().setDate(endDate.getDate() - 30));
    var _b = useState({ show: false }), error = _b[0], setError = _b[1];
    var _c = useState(false), showCharts = _c[0], setShowCharts = _c[1];
    var _d = useState(), historyPrints = _d[0], setHistoryPrints = _d[1];
    var _e = useState({ startDate: startDate, endDate: endDate }), filters = _e[0], setFilters = _e[1];
    var history = useHistory();
    var t = useTranslation().t;
    var _f = useGetHistoryPrints(), getHistoryPrints = _f[0], loading = _f[1];
    useEffect(function () {
        getHistoryPrints({ variables: { dashboardPrintsQueryDto: filters } }).then(function (response) {
            setHistoryPrints(response);
            console.log({ response: response });
        }).catch(function (error) {
            console.log({ error: error });
            setError({ show: true, message: error.graphQLErrors[0].message });
        });
    }, [filters]);
    return (_jsxs(Wrapper, { children: [_jsx(AppTopBar, __assign({ sectionName: t('main_menu.dashboard') }, { children: _jsxs("div", __assign({ style: { marginLeft: 5, fontSize: 16, color: '#000000', fontWeight: 600 } }, { children: ["/ ", t('main_menu.dashboard_sub_menu.prints')] })) })), _jsxs(ScrollContainer, { children: [_jsx(DashboardPrintsTableTools, { showCharts: setShowCharts, onFilters: setFilters }), !showCharts ?
                        _jsx(DashboardPrintsTable, { loading: loading, data: (_a = historyPrints === null || historyPrints === void 0 ? void 0 : historyPrints.data) === null || _a === void 0 ? void 0 : _a.dashboardPrints })
                        :
                            _jsx(DashboardCharts, { historyPrints: historyPrints })] }), _jsx(ModalFeedback, { title: error.message, visible: error.show, type: 'error', icon: '', simple: true, okBtnText: "".concat(t('general.buttons.close')), onOk: function () { return setError({ show: false, message: '' }); } })] }));
};
export default DashboardPrints;
