var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal as _Modal } from 'antd';
import styled from 'styled-components';
import { Button } from '../styles/Button';
import { useTranslation } from 'react-i18next';
var Modal = styled(_Modal)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .ant-modal-header, .ant-modal-footer{\n    padding: 0px !important;\n  }\n"], ["\n  .ant-modal-header, .ant-modal-footer{\n    padding: 0px !important;\n  }\n"])));
var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  max-height: fit-content;\n  padding: 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"], ["\n  max-height: fit-content;\n  padding: 0;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n"])));
var Header = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 16px 24px;\n  .title{\n    font-size: 18px;\n    font-weight: 600;\n  }\n  .extra-info{\n    font-size: 14px;\n    font-weight: 600;\n    color: ", ";\n\n    span{\n      color: ", ";\n      font-weight: 600;\n    }\n  }\n"], ["\n  padding: 16px 24px;\n  .title{\n    font-size: 18px;\n    font-weight: 600;\n  }\n  .extra-info{\n    font-size: 14px;\n    font-weight: 600;\n    color: ", ";\n\n    span{\n      color: ", ";\n      font-weight: 600;\n    }\n  }\n"])), function (props) { return props.theme.grayScale.gray5; }, function (props) { return props.theme.grayScale.gray0; });
var Footer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  padding: 12px 24px;\n  border-top: 1px solid ", ";\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 40px;\n\n  .cancel-btn{\n    text-decoration: underline;\n    cursor: pointer;\n    font-size: 12px;\n    font-weight: 600;\n    color: ", ";\n  }\n"], ["\n  width: 100%;\n  padding: 12px 24px;\n  border-top: 1px solid ", ";\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  gap: 40px;\n\n  .cancel-btn{\n    text-decoration: underline;\n    cursor: pointer;\n    font-size: 12px;\n    font-weight: 600;\n    color: ", ";\n  }\n"])), function (props) { return props.theme.helpers.borderColorInput; }, function (props) { return props.theme.grayScale.gray5; });
function ModalHeader(_a) {
    var title = _a.title, type = _a.type, total = _a.total;
    var t = useTranslation().t;
    return (_jsxs(Header, { children: [_jsx("div", __assign({ className: "title" }, { children: title })), type !== 'simple' && _jsxs("div", __assign({ className: "extra-info" }, { children: [t('sections.print_section.total_to_print'), "\u00A0", _jsx("span", { children: total })] }))] }));
}
export var ModalTable = function (_a) {
    var _b = _a.title, title = _b === void 0 ? '' : _b, widthOkBtn = _a.widthOkBtn, total = _a.total, table = _a.table, width = _a.width, visible = _a.visible, icon = _a.icon, status = _a.status, _c = _a.keyName, keyName = _c === void 0 ? '' : _c, _d = _a.type, type = _d === void 0 ? "success" : _d, _e = _a.simple, simple = _e === void 0 ? true : _e, _f = _a.simpleTextbtn, simpleTextbtn = _f === void 0 ? 'Cerrar' : _f, loadingConfirm = _a.loadingConfirm, _g = _a.loadingSimpleBtn, loadingSimpleBtn = _g === void 0 ? false : _g, onOk = _a.onOk, onCancel = _a.onCancel;
    var t = useTranslation().t;
    return (_jsx(Modal, __assign({ title: _jsx(ModalHeader, { title: title, type: type, total: total }), className: 'feedback-modal', transitionName: '', maskTransitionName: '', centered: true, open: visible, destroyOnClose: true, width: width ? width : 860, closable: false, footer: _jsxs(Footer, { children: [onCancel !== undefined ?
                    _jsx("div", __assign({ className: 'cancel-btn', onClick: onCancel }, { children: t('general.buttons.cancel') }))
                    :
                        null, onOk !== undefined ?
                    _jsx(Button, __assign({ type: "primary", style: { width: widthOkBtn ? widthOkBtn : 120, color: '#fff' }, onClick: onOk, loading: loadingConfirm ? loadingConfirm : false }, { children: simpleTextbtn ? simpleTextbtn : 'Enviar' })) :
                    null] }) }, { children: _jsx(Content, { children: table }) })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
