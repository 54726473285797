var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql, useMutation } from "@apollo/client";
var UPDATE_USER = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation UpdateUser($updateUserDto:UpdateUserDto!){\n  update(updateUserDto:$updateUserDto){\n    _id\n    fullName\n    userMail\n    position\n    role\n    createdAt\n    status\n  }\n}\n"], ["\n  mutation UpdateUser($updateUserDto:UpdateUserDto!){\n  update(updateUserDto:$updateUserDto){\n    _id\n    fullName\n    userMail\n    position\n    role\n    createdAt\n    status\n  }\n}\n"])));
export var useUpdateUser = function () {
    var _a = useMutation(UPDATE_USER, { context: { clientName: 'authPath' } }), updateUser = _a[0], _b = _a[1], loading = _b.loading, error = _b.error, data = _b.data, called = _b.called;
    return [updateUser, loading, error, data, called];
};
var templateObject_1;
