var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var UpdateIcon = function (_a) {
    var colorOne = _a.colorOne, colorTwo = _a.colorTwo;
    return (_jsx("svg", __assign({ xmlns: 'http://www.w3.org/2000/svg', width: '1em', height: '0.68em', viewBox: '0 0 26.476 18.13' }, { children: _jsxs("g", __assign({ id: 'Grupo_673', "data-name": 'Grupo 673', transform: 'translate(-407.593 -546.449)' }, { children: [_jsx("rect", { id: 'Rect\u00E1ngulo_259', "data-name": 'Rect\u00E1ngulo 259', width: '10', height: '13', transform: 'translate(415.831 551.515)', fill: colorTwo ? colorTwo : '#e9e9ed' }), _jsx("g", __assign({ id: 'noun-upload-113999', transform: 'translate(407.593 546.449)' }, { children: _jsx("path", { id: 'Trazado_89', "data-name": 'Trazado 89', d: 'M146.1,125.92c0-.092.007-.182.007-.275a7.588,7.588,0,0,0-13.688-4.513,5.481,5.481,0,0,0-7.408,4.255,5.727,5.727,0,0,0-3.1,4.982c0,3.213,2.874,5.818,6.419,5.818h13.637c3.546,0,6.42-2.6,6.42-5.818a5.608,5.608,0,0,0-2.285-4.449Zm-14.659.095,2.693-2.692a1.072,1.072,0,0,1,.171-.141l.031-.019a1.08,1.08,0,0,1,.154-.079l.051-.017a1.1,1.1,0,0,1,.151-.043.507.507,0,0,1,.062-.008l.035,0a.9.9,0,0,1,.109-.01.57.57,0,0,1,.078.007l.047,0a.759.759,0,0,1,.08.01.857.857,0,0,1,.123.035.753.753,0,0,1,.081.026,1.026,1.026,0,0,1,.122.063c.034.019.049.026.062.035a1.044,1.044,0,0,1,.169.139l2.694,2.694a1.076,1.076,0,0,1,0,1.522,1.1,1.1,0,0,1-1.522,0l-.86-.86v6.42a1.076,1.076,0,1,1-2.153,0v-6.42l-.859.86a1.1,1.1,0,0,1-1.522,0,1.076,1.076,0,0,1,0-1.522Z', transform: 'translate(-121.913 -118.056)', fill: colorOne ? colorOne : '#b5b5c2' }) }))] })) })));
};
export default UpdateIcon;
