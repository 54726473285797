var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
// import UserTableTools from './UserTableTools'
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  overflow: auto;\n  background-color: #F7F7F8;\n  display: flex;\n  flex-direction: column;\n"], ["\n  width: 100%;\n  height: 100%;\n  overflow: auto;\n  background-color: #F7F7F8;\n  display: flex;\n  flex-direction: column;\n"])));
var ScrollContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 16px;\n  width: 100%;\n  height: 100%;\n  overflow: auto;\n  display: flex;\n  flex-direction: column;\n"], ["\n  padding: 16px;\n  width: 100%;\n  height: 100%;\n  overflow: auto;\n  display: flex;\n  flex-direction: column;\n"])));
var Dashboard = function () {
    var history = useHistory();
    var location = useLocation();
    if (location.pathname === '/dashboard') {
        history.push("/dashboard/intake");
    }
    return (_jsx(_Fragment, {})
    // <Wrapper>
    //   <AppTopBar sectionName="Dashboard" />
    //   <ScrollContainer>
    //   </ScrollContainer>
    // </Wrapper>
    );
};
export default Dashboard;
var templateObject_1, templateObject_2;
