var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PrintersTable from './PrintersTable';
import AppTopBar from '../app/AppTopBar';
import { Wrapper, ScrollContainer } from '../styles/Section';
import PrintersTableTools from './PrintersTableTools';
import { ModalFeedback } from '../components/ModalFeedback';
import { useGetPrinters } from '../graphql/print/useGetPrinters';
import { useTranslation } from 'react-i18next';
var Printers = function (_a) {
    var socket = _a.socket;
    var _b = useState({ show: false }), error = _b[0], setError = _b[1];
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var _d = useState(false), readyToUpdate = _d[0], setReadyToUpdate = _d[1];
    var _e = useState([]), data = _e[0], setData = _e[1];
    var _f = useState(''), timeFromUpdate = _f[0], setTimeFromUpdate = _f[1];
    var history = useHistory();
    var t = useTranslation().t;
    var _g = useGetPrinters(), getPrinters = _g[0], _ = _g[1], __ = _g[2], printersData = _g[3];
    useEffect(function () {
        if (!readyToUpdate)
            return;
        socket.on('status_updates', function (status) {
            var arr = __spreadArray([], data, true);
            var now = Date.now();
            var miliseconds = now - new Date(now).getTime();
            var hours = miliseconds / 3600000;
            var minutes = (miliseconds % 3600000) / 60000;
            var seconds = ((miliseconds % 3600000) % 60000) / 1000;
            var lastUpdate = "".concat(hours > 1 ? Math.floor(hours) + ' horas' : '', " ").concat(minutes > 1 ? Math.floor(minutes) + ' minutos' : '', " ").concat(Math.floor(seconds), " segundos");
            setTimeFromUpdate(lastUpdate);
            var updated = arr.map(function (i) { return (i === null || i === void 0 ? void 0 : i.code) !== status.status.id ? i : __assign(__assign({}, i), { available: status.available, message: status.message, statusUpdate: __assign(__assign({}, status.status), { date: now }), timeEval: status.timeEval }); });
            setData(updated);
            console.log({ status: status });
        });
        return function () {
            socket.off('status_updates');
        };
    }, [readyToUpdate]);
    useEffect(function () {
        handleFetch();
    }, [printersData]);
    var handleFetch = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, finalData, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    setLoading(true);
                    return [4 /*yield*/, getPrinters()];
                case 1:
                    response = _a.sent();
                    finalData = response.data.printersFindAll.map(function (i) { return (__assign(__assign({}, i), { available: false, message: 'No updates', timeEval: 0, statusUpdate: {
                            producer: '',
                            ready: false,
                            id: '',
                            date: Date.now()
                        } })); });
                    setData(finalData);
                    setReadyToUpdate(true);
                    setLoading(false);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    setLoading(false);
                    setError({ show: true, message: error_1.graphQLErrors[0].message });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Wrapper, { children: [_jsx(AppTopBar, { sectionName: t('sections.printers_section.printers') }), _jsxs(ScrollContainer, { children: [_jsx(PrintersTableTools, {}), _jsx(PrintersTable, { loading: loading, data: data, timeFromUpdate: timeFromUpdate, onTimeUpdate: setTimeFromUpdate })] }), _jsx(ModalFeedback, { title: error.message, visible: error.show, type: 'error', icon: '', simple: true, okBtnText: "".concat(t('general.buttons.close')), onOk: function () { return setError({ show: false, message: '' }); } })] }));
};
export default Printers;
