var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Button } from '../../styles/Button';
import React from 'react';
import PrintIcon from '../../icons/PrintIcon';
import LocationIcon from '../../icons/LocationIcon';
import { useTranslation } from 'react-i18next';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 8px 32px;\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-top: 1px solid ", ";\n  border-bottom: 1px solid ", ";\n"], ["\n  padding: 8px 32px;\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-top: 1px solid ", ";\n  border-bottom: 1px solid ", ";\n"])), function (props) { return props.theme.colors.white; }, function (props) { return props.theme.grayScale.gray2; }, function (props) { return props.theme.grayScale.gray2; });
var Info = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 40px;\n\n  .info-item{\n    display: flex;\n    align-items: center;\n    gap: 10px;\n\n    &-icon{\n      font-size: 16px;\n      line-height: 0;\n    }\n    &-title{\n      font-size: 12px;\n      font-weight: 600;\n      color: ", ";\n    }\n    &-text{\n      font-size: 12px;\n      font-weight: 600;\n      color: ", ";\n      text-transform: uppercase;\n    }\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 40px;\n\n  .info-item{\n    display: flex;\n    align-items: center;\n    gap: 10px;\n\n    &-icon{\n      font-size: 16px;\n      line-height: 0;\n    }\n    &-title{\n      font-size: 12px;\n      font-weight: 600;\n      color: ", ";\n    }\n    &-text{\n      font-size: 12px;\n      font-weight: 600;\n      color: ", ";\n      text-transform: uppercase;\n    }\n  }\n"])), function (props) { return props.theme.grayScale.gray1; }, function (props) { return props.theme.grayScale.gray0; });
var ActionsGroup = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\n"], ["\n\n"])));
var Action = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\n"], ["\n\n"])));
var PrintManualListTools = function (_a) {
    var printer = _a.printer, onPrint = _a.onPrint;
    var history = useHistory();
    var _b = React.useState(''), searchBy = _b[0], setSearchBy = _b[1];
    var t = useTranslation().t;
    var handleChange = function (value) {
        setSearchBy(value);
    };
    return (_jsxs(Wrapper, { children: [_jsxs(Info, { children: [_jsxs("div", __assign({ className: "info-item" }, { children: [_jsx("div", __assign({ className: "info-item-icon" }, { children: _jsx(PrintIcon, { colorOne: '#B5B5C2', colorTwo: '#E9E9ED' }) })), _jsx("div", __assign({ className: "info-item-title" }, { children: t('general.labels.printer') })), _jsx("div", __assign({ className: "info-item-text" }, { children: printer === null || printer === void 0 ? void 0 : printer.name }))] })), _jsxs("div", __assign({ className: "info-item" }, { children: [_jsx("div", __assign({ className: "info-item-icon" }, { children: _jsx(LocationIcon, { color: '#B5B5C2' }) })), _jsx("div", __assign({ className: "info-item-title" }, { children: "WarehouseCode" })), _jsx("div", __assign({ className: "info-item-text" }, { children: printer === null || printer === void 0 ? void 0 : printer.name })), _jsx("div", __assign({ className: "info-item-title" }, { children: "/" })), _jsx("div", __assign({ className: "info-item-title" }, { children: "storageBinID" })), _jsx("div", __assign({ className: "info-item-text" }, { children: printer === null || printer === void 0 ? void 0 : printer.storageBinId }))] }))] }), _jsx(ActionsGroup, { children: _jsx(Action, { children: _jsx(Button, __assign({ style: { height: '44px' }, size: 'large', type: 'primary', onClick: function () { return onPrint(1); } }, { children: t('general.buttons.send_print') })) }) })] }));
};
export default PrintManualListTools;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
