import { jsx as _jsx } from "react/jsx-runtime";
import { Switch, Route } from 'react-router-dom';
import UpdatesYards from './UpdatesYards';
// import UserCreate from './UserCreate'
// import UserEdit from './UserEdit'
var UpdatesYardsRouter = function () {
    var mainPath = '/updates/yards-master';
    return (_jsx(Switch, { children: _jsx(Route, { path: mainPath, children: _jsx(UpdatesYards, {}) }) }));
};
export default UpdatesYardsRouter;
