import axios from 'axios';
import { API_PATH } from '../../constants/api';
export function getAllManual(_a) {
    var printerId = _a.printerId, stockCode = _a.stockCode, storageBin = _a.storageBin;
    var url = "".concat(API_PATH, "/materials/findAll");
    url += "?printerId=".concat(printerId);
    if (stockCode) {
        url += "&stockCode=".concat(stockCode);
    }
    if (storageBin) {
        url += "&storageBin=".concat(storageBin);
    }
    return axios({
        method: 'get',
        url: url,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(function (res) {
        if (res.status === 200) {
            return res.data;
        }
        else {
            throw Error('error');
        }
    })
        .catch(function (error) {
        throw Error(error);
    });
}
export function getByBulkFile(data) {
    var url = "".concat(API_PATH, "/materials/findByBulkFile");
    return axios({
        method: 'post',
        url: url,
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    })
        .then(function (res) {
        if (res.status === 201) {
            return res.data;
        }
        else {
            throw Error('error');
        }
    })
        .catch(function (error) {
        throw Error(error);
    });
}
export function getByRGRFile(data) {
    var url = "".concat(API_PATH, "/materials/findByRGRFile");
    return axios({
        method: 'post',
        url: url,
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    })
        .then(function (res) {
        if (res.status === 201) {
            return res.data;
        }
        else {
            throw Error('error');
        }
    })
        .catch(function (error) {
        throw Error(error);
    });
}
export function handleLoadMaster(data) {
    var url = "".concat(API_PATH, "/materials/loadMaster");
    return axios({
        method: 'post',
        url: url,
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    })
        .then(function (res) {
        if (res.status === 201) {
            return res.data;
        }
        else {
            throw Error('error');
        }
    })
        .catch(function (error) {
        throw Error(error);
    });
}
export function handleLoadWarehouses(data) {
    var url = "".concat(API_PATH, "/warehouses/loadWarehouses");
    return axios({
        method: 'post',
        url: url,
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    })
        .then(function (res) {
        if (res.status === 201) {
            return res.data;
        }
        else {
            throw Error('error');
        }
    })
        .catch(function (error) {
        throw Error(error);
    });
}
export function handleTicket(data) {
    var url = "".concat(API_PATH, "/tickets");
    return axios({
        method: 'post',
        url: url,
        data: data,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    })
        .then(function (res) {
        if (res.status === 201) {
            return res.data;
        }
        else {
            throw Error('error');
        }
    })
        .catch(function (error) {
        throw Error(error);
    });
}
