var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Form, Input as _Input, Select as _Select } from 'antd';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
var FormItems = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  column-gap: 20px;\n"], ["\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  column-gap: 20px;\n"])));
var FormItem = styled(Form.Item)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 300px;\n  .ant-form-item-label{\n    label{\n      font-size: 12px;\n      font-weight: 600;\n      color: ", ";\n    }\n  }\n  .ant-form-item-explain{\n    font-size: 12px;\n    color: ", ";\n  }\n"], ["\n  width: 300px;\n  .ant-form-item-label{\n    label{\n      font-size: 12px;\n      font-weight: 600;\n      color: ", ";\n    }\n  }\n  .ant-form-item-explain{\n    font-size: 12px;\n    color: ", ";\n  }\n"])), function (props) { return props.theme.grayScale.gray5; }, function (props) { return props.theme.colors.error; });
var Input = styled(_Input)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\n  background-color: ", " !important;\n  font-size: 12px;\n  height: 44px;\n  .ant-input{\n    font-weight: 600;\n    background-color: ", ";\n  }\n"], ["\n\n  background-color: ", " !important;\n  font-size: 12px;\n  height: 44px;\n  .ant-input{\n    font-weight: 600;\n    background-color: ", ";\n  }\n"])), function (props) { return props.theme.grayScale.gray8; }, function (props) { return props.theme.grayScale.gray8; });
var Select = styled(_Select)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-weight: 600;\n  font-size: 12px;\n"], ["\n  font-weight: 600;\n  font-size: 12px;\n"])));
var PrintersCreateForm = function (_a) {
    var form = _a.form;
    var t = useTranslation().t;
    return (_jsx(Form, __assign({ layout: 'vertical', autoComplete: 'off', form: form, requiredMark: false }, { children: _jsxs(FormItems, { children: [_jsx(FormItem, __assign({ label: t('general.labels.name'), name: 'name', rules: [{ required: true, message: 'Por favor ingresa el nombre' }] }, { children: _jsx(Input, {}) })), _jsx(FormItem, __assign({ label: t('general.labels.connection_code'), name: 'code', rules: [{ required: true, message: 'Por favor ingresa el email' }] }, { children: _jsx(Input, {}) })), _jsx(FormItem, __assign({ label: t('general.labels.storage_bin_id'), name: 'storageBinId', rules: [{ required: true, message: 'Por favor ingresa el storageBinId' }] }, { children: _jsx(Input, {}) })), _jsx(FormItem, __assign({ label: t('general.labels.status'), name: 'status', rules: [{ required: true, message: 'Por favor selecciona un estado' }] }, { children: _jsxs(Select, __assign({ size: "large", placeholder: "".concat(t('general.placeholders.status_select')) }, { children: [_jsx(Select.Option, __assign({ value: 'Enabled' }, { children: t('general.selects.status_select.enabled_two') })), _jsx(Select.Option, __assign({ value: 'Disabled' }, { children: t('general.selects.status_select.disabled_two') }))] })) }))] }) })));
};
export default PrintersCreateForm;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
