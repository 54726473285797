var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var PencilIcon = function (_a) {
    var colorOne = _a.colorOne, colorTwo = _a.colorTwo;
    return (_jsxs("svg", __assign({ id: 'noun-edit-1014264', xmlns: 'http://www.w3.org/2000/svg', width: '1em', height: '1em', viewBox: '0 0 23.151 23.163' }, { children: [_jsx("path", { id: 'Trazado_126', "data-name": 'Trazado 126', d: 'M148.681,80.725l.644-.644a4.78,4.78,0,0,1,6.761,6.761l-.644.644Z', transform: 'translate(-134.335 -78.681)', fill: colorTwo ? colorTwo : '#e9e9ed' }), _jsx("path", { id: 'Trazado_127', "data-name": 'Trazado 127', d: 'M224.307,144.521l-9.787,9.787-6.761-6.761,9.787-9.787Z', transform: 'translate(-204.553 -134.364)', fill: colorOne ? colorOne : '#b5b5c2' }), _jsx("path", { id: 'Trazado_128', "data-name": 'Trazado 128', d: 'M402.659,340.206l1.835-7.469V332.7c0-.032,0-.032.032-.064l6.664,6.664a.031.031,0,0,0-.032.032h-.032l-7.469,1.835c-.064,0-.129.032-.193.032a.924.924,0,0,1-.579-.225.837.837,0,0,1-.225-.773Z', transform: 'translate(-402.639 -318.041)', fill: colorOne ? colorOne : '#b5b5c2' })] })));
};
export default PencilIcon;
