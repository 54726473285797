var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql, useLazyQuery } from "@apollo/client";
var GET_USER = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query GetUserById($id: String!){\n    find(_id: $id){\n      _id\n      companyCode\n      createdAt\n      fullName\n      isNewUser\n      lastLogin\n      plantCode\n      position\n      role\n      status\n      userMail\n      username\n    }\n  }\n"], ["\n  query GetUserById($id: String!){\n    find(_id: $id){\n      _id\n      companyCode\n      createdAt\n      fullName\n      isNewUser\n      lastLogin\n      plantCode\n      position\n      role\n      status\n      userMail\n      username\n    }\n  }\n"])));
export var useGetUser = function () {
    var _a = useLazyQuery(GET_USER, { context: { clientName: 'authPath' } }), getUser = _a[0], _b = _a[1], loading = _b.loading, error = _b.error, data = _b.data, called = _b.called;
    return [getUser, loading, error, data, called];
};
var templateObject_1;
