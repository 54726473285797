var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ReactDOM from 'react-dom/client';
import { ConfigProvider } from 'antd';
import esES from 'antd/lib/locale-provider/es_ES';
import MainRouter from '../src/router/MainRouter';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from './styles/Global';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, ApolloLink } from '@apollo/client';
import Cookies from 'universal-cookie';
import { setContext } from '@apollo/client/link/context';
import AppProvider from './context/AppContext';
import { GRAPHQL_AUTH_PATH, GRAPHQL_PRINT_PATH } from './constants/api';
// import {I18nextProvider} from 'react-i18next';
// import i18n from './i18n';
import moment from 'moment';
import 'moment/locale/es';
import './index.less';
var theme = require('./styles/theme.ts');
moment.locale('es');
var cookies = new Cookies();
var authPath = createHttpLink({
    uri: GRAPHQL_AUTH_PATH
});
var printPath = createHttpLink({
    uri: GRAPHQL_PRINT_PATH
});
var authLink = setContext(function (_, _a) {
    var headers = _a.headers;
    var token = cookies.get('smartbot-web');
    return {
        headers: __assign(__assign({}, headers), { authorization: token ? "Bearer ".concat(token) : '' })
    };
});
var client = new ApolloClient({
    // link: authLink.concat(httpLink),
    link: authLink.concat(ApolloLink.split(function (operation) { return operation.getContext().clientName === 'authPath'; }, authPath, printPath)),
    cache: new InMemoryCache(),
});
var container = document.getElementById('app');
var root = ReactDOM.createRoot(container);
root.render(_jsx(AppProvider, { children: _jsx(ApolloProvider, __assign({ client: client }, { children: _jsx(ConfigProvider, __assign({ locale: esES }, { children: _jsxs(ThemeProvider, __assign({ theme: theme }, { children: [_jsx(MainRouter, {}), _jsx(GlobalStyle, {})] })) })) })) }));
