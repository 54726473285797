var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { gql, useMutation } from "@apollo/client";
var UPDATE_PRINTER = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation UpdatePrinter($updatePrinterDto: UpdatePrinterDto!){\n    update(updatePrinterDto:$updatePrinterDto){\n      _id\n      code\n      controlStock\n      currentStock\n      lastPurchaseDate\n      lastPurchaseQuantity\n      name\n      status\n      storageBinId\n    }\n  }\n"], ["\n  mutation UpdatePrinter($updatePrinterDto: UpdatePrinterDto!){\n    update(updatePrinterDto:$updatePrinterDto){\n      _id\n      code\n      controlStock\n      currentStock\n      lastPurchaseDate\n      lastPurchaseQuantity\n      name\n      status\n      storageBinId\n    }\n  }\n"])));
export var useUpdatePrinter = function () {
    var _a = useMutation(UPDATE_PRINTER, { context: { clientName: 'printPath' },
    }), updatePrinter = _a[0], _b = _a[1], loading = _b.loading, error = _b.error, data = _b.data, called = _b.called;
    return [updatePrinter, loading, error, data, called];
};
var templateObject_1;
