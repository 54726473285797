var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var FileIcon = function (_a) {
    var colorOne = _a.colorOne, colorTwo = _a.colorTwo;
    return (_jsx("svg", __assign({ xmlns: 'http://www.w3.org/2000/svg', width: '0.78em', height: '1em', viewBox: '0 0 18.333 23.425' }, { children: _jsxs("g", __assign({ id: 'noun-file-5267130', transform: 'translate(-173.603 -54.652)' }, { children: [_jsx("path", { id: 'Trazado_110', "data-name": 'Trazado 110', d: 'M185.121,63.919h6.815V74.46a3.617,3.617,0,0,1-3.617,3.617h-11.1A3.617,3.617,0,0,1,173.6,74.46V58.267a3.617,3.617,0,0,1,3.617-3.615h5.721v7.086a2.181,2.181,0,0,0,2.181,2.181Z', fill: colorOne ? colorOne : '#00d800' }), _jsx("path", { id: 'Trazado_111', "data-name": 'Trazado 111', d: 'M377.382,61.577A1.123,1.123,0,0,0,378.5,62.7h6.62l-7.743-8.046Z', transform: 'translate(-193.189 -0.001)', fill: colorTwo ? colorTwo : '#40ff00' })] })) })));
};
export default FileIcon;
